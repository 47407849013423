import { useEffect } from 'react'

interface IProps {
    lat: number;
    lon: number
    style?: React.CSSProperties
}

const { kakao } = window;

const KakaoMap = ({ lat, lon, style }: IProps) => {
    const addMarker = () => {
        const markerPosition = new kakao.maps.LatLng(lon, lat)

        return new kakao.maps.Marker({
            position: markerPosition,
        })
    }

    useEffect(() => {
        if (!kakao) return;
        var container = document.getElementById('map');
        var options = {
            center: new kakao.maps.LatLng(lon, lat),
            level: 7
        };
        var map = new kakao.maps.Map(container, options);

        addMarker().setMap(map)
    }, [kakao])


    return <>
        <div id="map" style={{ width: "100%", height: "180px", ...style }} />
    </>
}

export default KakaoMap;