import { Button } from "antd"
import { ReactComponent as CircleCheck } from "../../../../assets/images/icon/circle-check.svg"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";

const WellnessProgramBookConfirm = () => {
    const { form } = useSelector((state: RootState) => state.programBooking);

    const navigate = useNavigate();
    return <>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <div>
                <div>
                    <CircleCheck width={100} height={100} stroke={'var(--secondarys400)'} fill={'#ffffff'} />
                </div>
                <div className="sub-title/st4" style={{ marginTop: 10 }}>
                    신청이 완료되었습니다.
                </div>
                <div className="body/b3 secondary/s700" style={{ marginTop: 10 }}>예약이 확정되면 카카오 알림톡을 보내드립니다.<br />
                    확정 후 결제를 진행해주세요.
                </div>
            </div>
        </div>
        <div style={{ margin: 20, paddingTop: '20px', position: 'absolute', bottom: '0px', width: 'calc(100% - 40px)', maxWidth: '440px' }}>
            <Button
                color="default"
                variant="solid"
                className="sub-title/st3 base/white"
                style={{ width: '100%', height: '51px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                onClick={() => { navigate(`/wellness-program/${form.id}`, { replace: true }) }}>
                다음
            </Button>
        </div>
    </>
}

export default WellnessProgramBookConfirm