import api from './Api'

export const createFeedWeb = (content: string, imageUrlList: Array<string>) => {
    return api.post<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/web`, { content, imageUrlList });
}

export const getFeedListWeb = () => {
    return api.get<Array<IGetFeedListWebResponse>>(`${process.env.REACT_APP_API_DOMAIN}/feed/web/list`);
}

export const createFeedLikeWeb = (feedId: number) => {
    return api.post<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/like/web/${feedId}`);
}

export const deleteFeedLikeWeb = (feedId: number) => {
    return api.delete<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/like/web/${feedId}`);
}

export const createFeedClaimWeb = (createFeedClaimWebRequest: ICreateFeedClaimWebRequest) => {
    return api.post<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/claim/web`, createFeedClaimWebRequest);
}

export const getFeedWebByFeedId = (feedId: number, responseQuery?: string) => {
    const query = `query getFeedWebByFeedId($feedId:ID!) { getFeedWebByFeedId(feedId: $feedId) { ${responseQuery} } }`
    return api.post<IGraphqlResponse<{ getFeedWebByFeedId: IGetFeedListWebResponseGraphQl }>>('/graphql', { query, variables: { feedId: feedId } })
}

export const getProgramBookingWebByProgramBookingId = (id: number, responseQuery?: string) => {
    const query = `query getProgramBookingWebByProgramBookingId($programBookingId:ID!) { getProgramBookingWebByProgramBookingId(programBookingId: $programBookingId) { ${responseQuery} } }`
    return api.post<IGraphqlResponse<{ getProgramBookingWebByProgramBookingId: IProgramBookingWebResponse }>>('/graphql', { query, variables: { programBookingId: id } })
}

export const createFeedCommentWeb = (createFeedCommentWebRequest: ICreateFeedCommentWebRequest) => {
    return api.post<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/comment/web`, createFeedCommentWebRequest);
}

export const createFeedCommentLikeWeb = (feedCommentId: number) => {
    return api.post<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed-comment/like/web/${feedCommentId}`);
}

export const deleteFeedCommentLikeWeb = (feedCommentId: number) => {
    return api.delete<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed-comment/like/web/${feedCommentId}`);
}

export const deleteFeedCommentWeb = (feedCommentId: number) => {
    return api.delete<boolean>(`${process.env.REACT_APP_API_DOMAIN}/feed/comment/web/${feedCommentId}`);

}