import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCancelProgramBookingStatusWebByCancelProgramBookingStatusRequest, useGetProgramBookingWebByProgramBookingId } from "./model";
import { TopBar } from "../../../../../widgets";
import { Button, Divider, Flex, Modal, message } from "antd";
import { ReactComponent as ArrowRight } from "../../../../../assets/images/icon/arrow-right.svg"
import dayjs from "dayjs";
import { useState } from "react";
import { moneyStringFormatByNumber } from "../../../../../utils";
import { setUrl } from "../../../../../service";

const ProgramBookingDetail = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const id = Number(useParams().id);
    const { data, isFetched } = useGetProgramBookingWebByProgramBookingId(id, 'programId programBookingId programThumbnailImageUrl programTitle programDescription memberId memberName memberMobile targetDateTime programIsPaid programBookingIsPaid programBookingStatus programRefundNotiHtml totalPayAmount programBookingSelectOptionList { programSelectOptionName programSelectOptionItemNameList } programBookingSubjectOptionList { programSubjectOptionName content } programBookingTicketWebResponseList { programBookingTicketCount programTicketName programTicketPayAmount programBookingTicketPrice }')
    const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
    const cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest = useCancelProgramBookingStatusWebByCancelProgramBookingStatusRequest()

    const clickPaymentButton = () => {
        if (!data) return;
        const state = {
            defaultPrice: data.totalPayAmount,
            price: data.totalPayAmount,
            orderId: `program${data.programBookingId}-${dayjs().format('YYYYMMDDHHmmssZ').replaceAll('+', '').replaceAll(':', '')}`,
            orderName: `${data.programTitle} ${dayjs(data.targetDateTime).format("YYYYMMDDHHmmss")}`,
            couponId: '',
            issuedYogaCouponId: '',
            pointValue: 0,
            selectedConselingBranch: 'SEOLLEUNG',
        }

        setUrl(location.pathname)
        navigate('/checkout', { state: state })
    }

    return <>
        <TopBar title="예약 상세" rightIcon={false} />
        <div style={{ padding: 20 }}>
            <div style={{ marginTop: 10 }}>
                {data?.programBookingStatus === '예약 대기' ? <>
                    <div className="sub-title/st5 primary/p800">예약 확정</div>
                    <div className="body/b3" style={{ marginTop: 4 }}>예약이 확정되면 카카오 알림톡을 보내드립니다.</div>
                </>
                    : data?.programBookingStatus === '예약 확정' ? <>
                        <div className="sub-title/st5">에약이 확정되었습니다</div>
                        <div className="body/b3" style={{ marginTop: 4 }}> 까지 결제를 완료해주세요.</div>
                    </>
                        : '---'
                }
            </div>

            <div style={{ marginTop: 20, padding: 12, backgroundColor: 'var(--secondarys100)' }} onClick={() => navigate(`/wellness-program/${data?.programId}`)}>
                <Flex justify="space-between" style={{ alignItems: 'center' }}>
                    <Flex style={{ alignItems: 'center' }} gap={16}>
                        <div style={{ width: 56, height: 56 }}>
                            <img src={data?.programThumbnailImageUrl} alt="프로그램 이미지" width={'100%'} height={'100%'} style={{ borderRadius: 4, objectFit: 'cover' }} />
                        </div>
                        <div className="sub-title/st3">{data?.programTitle}</div>
                    </Flex>
                    <div><ArrowRight width={16} stroke="var(--secondarys400)" /></div>
                </Flex>
            </div>

            <div style={{ marginTop: 24 }} className="sub-title/st3">예약 세부 정보</div>
            <div style={{ marginTop: 24 }} className="body/b2 secondary/s500">이용 일시</div>
            <div className="body/b3">{dayjs(data?.targetDateTime).format("YYYY.MM.DD HH:mm")}</div>
            <div style={{ marginTop: 16 }} className="body/b2 secondary/s500">티켓</div>
            {data?.programBookingTicketWebResponseList.map((bookingTicket: IProgramBookingTicketWebResponse) => <Flex>
                <div className="body/b3" style={{ width: 200 }}>{bookingTicket.programTicketName}</div>
                <div className="body/b3">{bookingTicket.programBookingTicketCount}명</div>
                <div></div>
            </Flex>)}
            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', marginBlock: 20 }} />
            <div style={{ marginTop: 24 }} className="body/b2 secondary/s500">이름</div>
            <div className="body/b3">{data?.memberName}</div>
            <div style={{ marginTop: 16 }} className="body/b2 secondary/s500">연락처</div>
            <div className="body/b3">{data?.memberMobile}</div>
            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', marginBlock: 20 }} />
            {data?.programBookingSelectOptionList.map((selectOption: IProgramBookingSelectOptionWebResponse) => <>
                <div style={{ marginTop: 16 }} className="body/b2 secondary/s500">{selectOption.programSelectOptionName}</div>
                <div className="body/b3">{selectOption.programSelectOptionItemNameList.join(', ')}</div>
            </>)}
            {data?.programBookingSubjectOptionList.map((subjectOption: IProgramBookingSubjectOptionWebResponse) => <>
                <div style={{ marginTop: 16 }} className="body/b2 secondary/s500">{subjectOption.programSubjectOptionName}</div>
                <div className="body/b3 base-black">{subjectOption.content}</div>
            </>)}
        </div>
        {data?.programIsPaid ? <><Divider style={{ borderBlockStart: '6px solid var(--secondarys100)', marginBlock: 4 }} />
            <div style={{ padding: 20 }}>
                <Flex>
                    <div className="sub-title/st3">결제 정보</div>
                </Flex>
                <Flex justify="space-between" style={{ alignItems: 'center', marginTop: 24, cursor: 'pointer' }} onClick={() => navigate('/program/book/detail/pay-info', { state: { programBookingTicketWebResponseList: [...data?.programBookingTicketWebResponseList], totalPayAmount: data?.totalPayAmount } })}>
                    <div>
                        <div style={{}} className="body/b2 secondary/s500">결제 예정 금액</div>
                        <div className="body/b3">{moneyStringFormatByNumber(data?.totalPayAmount)} 원</div>
                    </div>
                    <div><ArrowRight width={16} stroke="var(--secondarys400)" /></div>
                </Flex>
            </div></> : <></>}

        <Divider style={{ borderBlockStart: '6px solid var(--secondarys100)', marginBlock: 4 }} />
        <div style={{ padding: 20 }}>
            <div className="sub-title/st3">취소・환불 규정</div>
            {data && <div className="body/b3 secondary/s850" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.programRefundNotiHtml }} />}
        </div>
        <div style={{ marginTop: 40, paddingBottom: 40, padding: 20 }}>
            {data?.programBookingStatus === '예약 대기' ?
                <Button color="default" variant="outlined" style={{ width: '100%', height: '50px' }} onClick={() => setIsOpenCancelModal(true)}>취소하기</Button>
                : data?.programBookingStatus === '예약 확정' ?
                    data.programBookingIsPaid ? <>
                        <Button color="default" variant="solid" style={{ width: '100%', height: '50px' }} onClick={() => { }}>티켓 확인하기</Button>
                        <Button color="default" variant="outlined" style={{ width: '100%', height: '50px', marginTop: 10 }} onClick={() => { }}>취소 요청하기</Button>
                    </>
                        : <Button color="default" variant="solid" style={{ width: '100%', height: '50px' }} onClick={() => clickPaymentButton()}>{moneyStringFormatByNumber(data?.totalPayAmount)}원 결제하기</Button>
                    : <>---</>}
        </div>

        <Modal
            open={isOpenCancelModal}
            closable={false}
            centered
            onOk={() => console.log('ok')}
            onCancel={() => setIsOpenCancelModal(false)}
            footer={[
                <Flex key="footer-buttons" style={{ marginTop: '25px' }} gap={12}>
                    <Button
                        key="back"
                        onClick={() => setIsOpenCancelModal(false)}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', backgroundColor: 'white', color: 'black' }}>
                        돌아가기
                    </Button>
                    <Button
                        type="primary"
                        key="submit"
                        onClick={() => {
                            const cancelParams: ICancelProgramBookingStatusRequest = {
                                programBookingId: id,
                                status: "CANCEL"
                            }

                            cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest.mutate(cancelParams, {
                                onSuccess: res => {
                                    if (!res.data.errors) {
                                        message.success('성공했습니다.')
                                        navigate(`/my/reservation-history/${data?.memberId}`)
                                    } else {
                                        message.error('에러가 발생했습니다 개발팀에 문의해주세요')
                                    }
                                }
                            })
                        }}
                        style={{ width: '50%', height: '50px', fontSize: '16px', fontWeight: '600', margin: 0, borderWidth: '1px' }}>
                        에약 취소
                    </Button>
                </Flex>
            ]}>
            <div>
                <div className="sub-title/st4" style={{ textAlign: 'center' }}>예약 취소</div>
                <div className="body/b2 secondary/s800" style={{ marginTop: 8 }}>프로그램 예약을 취소하시겠습니까?</div>
            </div>
        </Modal>
    </>
}
export default ProgramBookingDetail