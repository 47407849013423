import { Divider, Flex } from "antd"
import { ReactComponent as Camera } from "../../../../assets/images/icon/camera.svg"
import { ReactComponent as Img } from "../../../../assets/images/icon/img.svg"
import { useNavigate } from "react-router-dom"

interface IProps {
    nickName: string
}

const CreateFeedForm = ({ nickName }: IProps) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/create/feed?placeholder=요즘 발견한 나만의 웰니스 루틴은?');
    }

    return <>
        <div className="sub-title/st2">{nickName}</div>
        <div className="body/b3 secondary/s400" onClick={() => onClick()}>요즘 발견한 나만의 웰니스 루틴은?</div>
        <Flex style={{ marginTop: 10, justifyContent: 'right', gap: 10 }}>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/create/feed?initForm=photo&placeholder=요즘 발견한 나만의 웰니스 루틴은?')}><Img /></div>
            <div style={{ cursor: 'pointer' }} onClick={() => navigate('/create/feed?initForm=camera&placeholder=요즘 발견한 나만의 웰니스 루틴은?')}><Camera /></div>
        </Flex>
    </>
}

export default CreateFeedForm