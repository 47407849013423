import { createReducer } from 'typesafe-actions';
import { SelectedYogaCenteAndDaterState, SelectedYogaCenteAndDaterAction } from './types';
import { UPDATE_SELECTEDYOGACENTERANDDATE } from './actions';
import dayjs from 'dayjs';


const initialState: SelectedYogaCenteAndDaterState = {
    date: dayjs().format('YYYY-MM-DD'),
    yogaCenterId: 0,
};

const selectedYogaCenterAndDate = createReducer<SelectedYogaCenteAndDaterState, SelectedYogaCenteAndDaterAction>(initialState, {
    [UPDATE_SELECTEDYOGACENTERANDDATE]: (state, action) => ({
        ...state,
        date: action.payload.date,
        yogaCenterId: action.payload.yogaCenterId,
    })
});

export default selectedYogaCenterAndDate;