import { AxiosResponse } from 'axios';
import api from './Api'

export const updateFcmToken = (tokens: IUpdateFcmToken) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/mobile/fcm/tokens`, tokens);
}

export const getMemberDataByToken = (): Promise<AxiosResponse<IMember, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/member/token`);
}

export const getMemberCouponPointTicket = (): Promise<AxiosResponse<IMemberPointCouponForResign, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/mobile/member/resign/token`);
}

export const getMemberByMemberId = (): Promise<AxiosResponse<MemberMyWebResponseV2>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/my/web/v2`);
}

export const getResignDataByMemberId = (): Promise<AxiosResponse<IMemberMyResignWebResponseV2>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/my/resign/web/v2`);
}

export const getMyReservationHistory = (): Promise<AxiosResponse<Array<IGetMyReservationHistoryResponse>>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/member/my/reservation/history`);
}
