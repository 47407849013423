import { useQuery } from "@tanstack/react-query"
import { getMyReservationHistory } from "../../../../service/member";

const useGetMyReservationHistory = () => {
    const query = useQuery({
        queryKey: ['getMyReservationHistory'],
        queryFn: () => getMyReservationHistory(),
        select: res => res.data
    });
    return query;
}

export { useGetMyReservationHistory };