import { Button, Flex, Modal, message } from "antd";
import "./index.css"
import { ReactComponent as Check } from "../../../../assets/images/icon/check.svg";
import { useState } from "react";
import { useCreateFeedClaimWeb } from "../recommendFeed/model";

interface IProps {
    feedId: number
    isOpen: boolean
    setIsOpen: Function
}

interface IClaimData {
    id: number
    label: string
    value: number

}
const claimDataList: Array<IClaimData> = [
    { id: 1, label: '스팸/홍보 도배글입니다.', value: 1 },
    { id: 2, label: '음란물입니다.', value: 2 },
    { id: 3, label: '스팸/홍보 도배글입니다.', value: 3 },
    { id: 4, label: '불법 정보를 포함하고 있습니다.', value: 4 },
    { id: 5, label: '청소년에게 유해한 내용입니다.', value: 5 },
    { id: 6, label: '욕설/생명경시/혐오/차별적 표현입니다.', value: 6 },
    { id: 7, label: '개인정보 노출 게시물입니다.', value: 7 },
    { id: 8, label: '불쾌한 표현이 있습니다.', value: 8 }
]

const FeedClaimBottomSheet = ({ feedId, isOpen, setIsOpen }: IProps) => {
    const contentHeight = 560
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;

    const [selectedCalimId, setSelectedCalimId] = useState<number>(0);

    const createNickNameWeb = useCreateFeedClaimWeb((res: any) => {
        if (res.data) {
            message.success("신고가 접수되었습니다.")
            setIsOpen(false)
        }
    })

    const onClickCalimCreateButton = () => {
        setIsOpen(false)
        createNickNameWeb.mutate({ feedId: feedId, category: claimDataList.filter((claimData: IClaimData) => claimData.value === selectedCalimId)[0].label })
    }

    return <>
        <Modal transitionName="custom-modal"
            style={{ top: `calc(100vh - ${selectedCalimId ? contentHeight : contentHeight - 50}px)`, padding: 0, margin: 0 }}
            styles={{ content: { minWidth: minWidth, height: `${contentHeight}px`, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px' } }}
            open={isOpen} closeIcon={null} footer={null} onCancel={() => {
                setSelectedCalimId(0)
                setIsOpen(false)
            }}>
            <div className="title/t1" style={{ color: '#111111', marginBottom: 'var(--space20)' }}>신고</div>

            {claimDataList.map((claim: IClaimData) =>
                <Flex
                    justify="space-between"
                    style={{ padding: 'var(--space12) 0px var(--space12) var(--space12)', cursor: 'pointer' }}
                    onClick={() => { setSelectedCalimId(claim.value) }}
                >
                    <div className={claim.value === selectedCalimId ? 'sub-title/st3' : 'body/b3'} style={{ lineHeight: 1.5 }}>
                        {claim.label}
                    </div>
                    {claim.value === selectedCalimId && <Check width={16} height={16} stroke={'#000000'} />}
                </Flex>)}

            {selectedCalimId !== 0 && <div style={{ paddingTop: '20px' }}>
                <Button
                    color="default"
                    variant="outlined"
                    style={{ width: '100%', height: '51px' }}
                    onClick={() => onClickCalimCreateButton()}>
                    신고 접수
                </Button>
            </div>}


        </Modal >
    </>
}

export { FeedClaimBottomSheet };


