import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
    carouselList: ICarousel[];
}

interface ICarousel {
    id: number,
    title: string,
    thumbnailImageUrl: string,
    programPeriod: Array<string>,
    bookingPeriod: Array<string>,
    locationName: string
}

const WellnessProgramCalrousel = ({ carouselList }: IProps) => {
    const [current, setCurrent] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [startX, setStartX] = useState<number>(0);
    const [dragOffset, setDragOffset] = useState<number>(0);
    const [autoSlide, setAutoSlide] = useState<boolean>(true);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const slideMarginInline = (idx: number) => {
        const isFirstItem = idx === 0 && current === 0;
        const isLastItem = idx === carouselList.length - 1;
        const isAtEnd = current === carouselList.length - 1;

        const marginLeft = (isFirstItem ? '6%' : isAtEnd ? '1.7%' : '3.5%');
        const marginRight = isLastItem ? '6%' : isAtEnd ? '1.7%' : '0%';

        return marginLeft + marginRight
    }
    const calculateTranslateX = () => {
        if (current === 0) { return 0; }
        const baseValue = current * 90.2;
        const extraGap = 2.66 * current * 0.5;
        return baseValue + extraGap;
    };

    const startAutoSlide = () => {
        return setInterval(() => {
            if (autoSlide) {
                setCurrent((curr) => (curr === carouselList.length - 1 ? 0 : curr + 1));
            }
        }, 3000);
    };

    useEffect(() => {
        const interval = startAutoSlide();
        return () => clearInterval(interval);
    }, [carouselList.length, autoSlide]);


    const onDragStart = (e: React.MouseEvent | React.TouchEvent) => {
        const clientX = e instanceof MouseEvent ? e.clientX : (e as React.TouchEvent).touches?.[0].clientX;
        setIsDragging(true);
        setStartX(clientX);
        setAutoSlide(false);
    };


    const onDragMove = (e: React.MouseEvent | React.TouchEvent) => {
        if (!isDragging) return;
        const clientX = e instanceof MouseEvent ? e.clientX : (e as React.TouchEvent).touches?.[0].clientX;
        setDragOffset(clientX - startX);
    };


    const onDragEnd = () => {
        if (!isDragging) return;
        setIsDragging(false);
        const threshold = 100;
        if (dragOffset > threshold) {
            setCurrent((prev) => (prev === 0 ? carouselList.length - 1 : prev - 1));
        } else if (dragOffset < -threshold) {
            setCurrent((prev) => (prev === carouselList.length - 1 ? 0 : prev + 1));
        }
        setDragOffset(0);
        setAutoSlide(true);
    };
    return <>
        <div style={{ display: "flex", overflowX: 'hidden', }} ref={ref}
            onMouseDown={onDragStart}
            onTouchStart={onDragStart}
            onMouseMove={onDragMove}
            onTouchMove={onDragMove}
            onMouseUp={onDragEnd}
            onTouchEnd={onDragEnd}
            onMouseLeave={onDragEnd}
        >
            <div style={{
                display: "flex", transform: `translateX(-${calculateTranslateX() - (dragOffset / window.innerWidth) * 100}%)`,
                transition: isDragging ? "none" : "transform 0.5s ease",
                width: '100%'
            }}>
                {carouselList.map((carousel, idx) => <div key={idx} style={{ minWidth: '93.4%', }}>
                    <div style={{ position: 'relative', borderRadius: '8px', paddingTop: '59.2%', marginInline: slideMarginInline(idx), backgroundImage: `url(${carousel.thumbnailImageUrl})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }} onClick={() => navigate(`/wellness-program/${carousel.id}`)}>
                        <div className="caption/c1" style={{ position: 'absolute', top: '0', right: '0', backgroundColor: 'var(--primaryp700)', padding: '4px 8px', borderRadius: '4px', color: 'var(--basewhite)' }}>
                            {/* {carousel.isCanReseisCanReservationrvation ? '예약 가능' : '예약 마감'} */}
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left', gap: '4px', paddingTop: '12px', marginInline: slideMarginInline(idx) }}>
                        <div className="sub-title/st3" style={{ whiteSpace: 'pre', color: 'var(--baseblack)' }}>
                            {carousel.title}
                        </div>
                        <div className="body/b1" style={{ whiteSpace: 'pre', color: 'var(--secondarys500)' }}>
                            {dayjs(carousel.programPeriod[0]).format('YYYY-MM-DD')} - {dayjs(carousel.programPeriod[1]).format('YYYY-MM-DD')} | {carousel.locationName}
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </>
}
export { WellnessProgramCalrousel }