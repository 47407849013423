import { RouteObject } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';
import { TosPaymentSuccess } from '../views/tosPayment/Success';
import InvitationDosanProgram from '../views/invitationDosanProgram';
import Resign from '../views/resign';
import PremiumMembership from '../views/premiumMembership';
import PrivateClassApply from '../views/privateClass/privateClassApply';
import PrivateClassApplyForm from '../views/privateClass/privateClassApplyForm';
import PrivateClassApplyHistory from '../views/privateClass/privateClassApplyHistory';
import PrivateClassApplyDetail from '../views/privateClass/privateClassApplyDetail';
import Home from '../views/home';
import Login from '../views/login';
import KakaoLoginCallback from '../views/loginWeb/kakaoLoginCallback';
import NaverLoginCallback from '../views/loginWeb/naverLoginCallback';
import KakaoLoginMobileAppCallback from '../views/loginMobileApp/Kakao/callback'
import NaverLoginMobileAppCallback from '../views/loginMobileApp/Naver/callback'
import AppleLoginMobileAppCallback from '../views/loginMobileApp/Apple/callback';
import { Notification } from '../views/home/notification'
import PopupTicket from '../views/popupTicket'
import { PayInfo } from '../views/payInfo'
import { PointUsePolicy } from '../shared/termAndPolicy/pointUsePolicy'
import { RefundPolicy } from '../shared/termAndPolicy/refundPolicy'
import CheckOut from '../views/tosPayment/checkout'
import MemberShip from '../views/memberShip'
import ScheduleInfo from '../views/scheduleInfo'
import AppGuide from '../views/appGuide'
import MyLecture from '../views/my/lecture'
import IssuedCouponDetail from '../views/issuedCoupon/detail'
import { IssuedWellnessTicketDetail } from '../views/my/issuedWellnessTicket/detail'
import TeacherDetail from '../views/teacher/detail'
import Lounge from '../views/lounge'
import PopupSection from '../views/popupsection'
import Event from '../views/my/event'
import EventDetail from '../views/my/event/detail'
import { My } from '../views/my';
import { Setting } from '../views/my/setting';
import { MyReward } from '../views/my/reward';
import { TrainingHistory } from '../views/my/trainingHistory';
import { PersonInfoPolicy } from '../shared/termAndPolicy/personInfoPolicy';
import { MarketingAgreement } from '../shared/termAndPolicy/marketingAgreement';
import { ServiceUseTerm } from '../shared/termAndPolicy/serviceUseTerm';
import { PaidHistory } from '../views/my/paidHistory';
import { PaidHistoryDetail } from '../views/my/paidHistory/detail';
import { Reservation } from '../views/reservation';
import { NoticePopupDetail } from '../views/home/notification/noticePopupDetail';
import { YogaCenterDetail } from '../views/home/yogaCenterDetail';
import { WellnessLecture } from '../views/wellnessLecture';
import IssuedYogaCouponDetail from '../views/issuedYogaCoupon/detail';
import { WellnessTicketGroup } from '../views/wellnessTicketGroup';
import { WellnessTicketGroupDetail } from '../views/wellnessTicketGroup/detail';
import UpdateReview from '../views/review/update';
import ViewReview from '../views/review/view';
import WellnessProgramDetail from '../views/wellnessProgram/detail';
import { Explore } from '../views/explore';
import { Community } from '../views/community';
import { WellnessProgramBookFirstStep } from '../views/wellnessProgram/book/firstStep';
import { WellnessProgramBookSecondStep } from '../views/wellnessProgram/book/secondStep';
import WellnessProgramBookConfirm from '../views/wellnessProgram/book/confirm';
import CreateProgramReview from '../views/review/create/CreateProgramReview';
import CreateWellnessLectureReview from '../views/review/create/CreateWellnessLectureReview';
import ReservationHistory from '../views/my/reservationHistory';
import CreateFeed from '../views/community/create';
import Notice from '../views/my/notice';
import ProgramBookingDetail from '../views/my/reservationHistory/detail/programBookingDetail';
import ProgramBookingPayInfo from '../views/my/reservationHistory/detail/programBookingPayInfo';
import CommunityFeedDetail from '../views/communityFeedDetail';

const MainRoutes: RouteObject = {
        path: '/',
        element: <MainLayout />,
        children: [
                { path: '/', element: <Home /> },
                { path: '/my', element: <My /> },
                { path: '/my/reward', element: <MyReward /> },
                { path: '/my/reward/:isActiveTab', element: <MyReward /> },
                { path: '/my/training-history', element: <TrainingHistory /> },
                { path: '/setting', element: <Setting /> },
                { path: '/login', element: <Login /> },
                { path: '/kakaologin/callback', element: <KakaoLoginCallback /> },
                { path: '/naverlogin/callback', element: <NaverLoginCallback /> },
                { path: '/lecture/:id', element: <WellnessLecture /> },
                { path: '/reservation', element: <Reservation /> },
                { path: '/kakaologin/mobile-app/callback', element: <KakaoLoginMobileAppCallback /> },
                { path: '/naverlogin/mobile-app/callback', element: <NaverLoginMobileAppCallback /> },
                { path: '/notification', element: <Notification /> },
                { path: '/notice', element: <Notice /> },
                { path: '/notice-popup/:id', element: <NoticePopupDetail /> },
                { path: '/popup/ticket/:id', element: <PopupTicket /> },
                { path: '/issued-wellness-ticket/:id', element: <IssuedWellnessTicketDetail /> },
                { path: '/pay-info/:wellnessTicketId', element: <PayInfo /> },
                { path: '/applelogin/callback', element: <AppleLoginMobileAppCallback /> },
                { path: '/serviceUseTerm', element: <ServiceUseTerm /> },
                { path: '/personInfoPolicy', element: <PersonInfoPolicy /> },
                { path: '/pointUsePolicy', element: <PointUsePolicy /> },
                { path: '/refundPolicy', element: <RefundPolicy /> },
                { path: '/marketingAgreement', element: <MarketingAgreement /> },
                { path: '/checkout', element: <CheckOut /> },
                { path: '/success', element: <TosPaymentSuccess /> },
                { path: '/member-ship', element: <MemberShip /> },
                { path: '/schedule-info', element: <ScheduleInfo /> },
                { path: '/app-guide', element: <AppGuide /> },
                { path: '/mylecture/teacher/:id', element: <MyLecture /> },
                { path: '/my/issued-coupon/:id', element: <IssuedCouponDetail /> },
                { path: '/my/issued-yoga-coupon/:id', element: <IssuedYogaCouponDetail /> },
                { path: '/teacher/:id', element: <TeacherDetail /> },
                { path: '/lounge', element: <Lounge /> },
                { path: '/popup-section', element: <PopupSection /> },
                { path: '/event', element: <Event /> },
                { path: '/event/:id', element: <EventDetail /> },
                { path: '/invitation/dosan/program/:step?', element: <InvitationDosanProgram /> },
                { path: '/resign', element: <Resign /> },
                { path: '/premium/membership', element: <PremiumMembership /> },
                { path: '/private/class/apply', element: <PrivateClassApply /> },
                { path: '/private/class/apply/form', element: <PrivateClassApplyForm /> },
                { path: '/private/class/apply/detail/:id', element: <PrivateClassApplyDetail /> },
                { path: '/private/class/apply/history', element: <PrivateClassApplyHistory /> },
                { path: '/my/paid-history/:memberId', element: <PaidHistory /> },
                { path: '/my/paid-history/:memberId/:id', element: <PaidHistoryDetail /> },
                { path: '/my/reservation-history/:memberId', element: <ReservationHistory /> },
                { path: '/yoga-center/:id', element: <YogaCenterDetail /> },
                { path: '/wellness-ticket-group', element: <WellnessTicketGroup /> },
                { path: '/wellness-ticket-group/:id', element: <WellnessTicketGroupDetail /> },
                // { path: '/review/create/:wellnessLectureId', element: <CreateReview /> },
                { path: '/review/create/:wellnessLectureId', element: <CreateWellnessLectureReview /> },
                { path: '/review/program/create/:programId', element: <CreateProgramReview /> },
                { path: '/review/update/:wellnessLectureId', element: <UpdateReview /> },
                { path: '/review/view/:wellnessLectureId', element: <ViewReview /> },
                { path: '/explore', element: <Explore /> },
                { path: '/wellness-program/:id', element: <WellnessProgramDetail /> },
                { path: '/wellness-program/book/first-step/:id', element: <WellnessProgramBookFirstStep /> },
                { path: '/wellness-program/book/second-step/:id', element: <WellnessProgramBookSecondStep /> },
                { path: '/wellness-program/book/confirm', element: <WellnessProgramBookConfirm /> },
                { path: '/community', element: <Community /> },
                { path: '/create/feed', element: <CreateFeed /> },
                { path: '/community/feed/:id', element: <CommunityFeedDetail /> },
                { path: '/program/book/detail/:id', element: <ProgramBookingDetail /> },
                { path: '/program/book/detail/pay-info', element: <ProgramBookingPayInfo /> },



        ],
};

export default MainRoutes;