import { useMutation } from "@tanstack/react-query"
import { createProgramBookingByMemberIdAndCreateProgramBookingWebRequest } from "../../../../../service/program";

const useCreateProgramBookingByMemberIdAndCreateProgramBookingWebRequest = () => {
    const mutation = useMutation({
        mutationFn: createProgramBookingByMemberIdAndCreateProgramBookingWebRequest,
    });

    return mutation;
}

export { useCreateProgramBookingByMemberIdAndCreateProgramBookingWebRequest }