import { ReactComponent as Close } from '../../../../assets/images/icon/close.svg'
import { useMyContext } from "../../../../entities/context";

interface IProps {
    thema?: 'dark' | 'white';
    position?: React.CSSProperties['position'] | undefined;
    style?: React.CSSProperties;
    onClickCloseBtn: Function
}

const TopBarButton = ({ onClickCloseBtn, thema = 'white', position = 'static', style }: IProps) => {
    const { topHeight } = useMyContext();
    return <>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                position: position,
                top: 0,
                paddingTop: (topHeight + 'px'),
                zIndex: 2,
                width: "100%",
                maxWidth: '480px',
                transition: "background-color 0.3s, color 0.3s",
                backgroundColor: thema === 'dark' ? "var(--basewhite)" : "transparent",
                ...style
            }}>
            <Close width={24} height={24} stroke="black"
                style={{ cursor: "pointer", padding: 'var(--space12)', marginInline: 'var(--space16)' }}
                onClick={() => onClickCloseBtn()} />
            <div className="title/t2" style={{ padding: '13px' }}>리뷰 작성</div>
            <div style={{ width: '24px', padding: '12px', marginInline: 'var(--space16)' }}></div>
        </div >

    </>
}

export { TopBarButton }