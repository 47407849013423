import { useNavigate, useParams } from "react-router-dom";
import { isLogin } from "../../../utils";
import { useEffect, useState } from "react";
import { getWellnessLectureDetailByWellnessLectureId } from "../../../service/wellnessLecture";
import { Button, Flex, Input, Modal, Rate, message } from "antd";
import ImageUploader from "../ui/ImageUploader";
import { createWellnessLectureReview } from "../../../service/wellnessLectureReview";
import { ReactComponent as CircleCheck } from "../../../assets/images/icon/circle-check.svg";
import { ReactComponent as Close } from "../../../assets/images/icon/close.svg";
import dayjs from "dayjs";
import { TopBarButton } from "../ui/topBarButton";

const { TextArea } = Input;

const CreateWellnessLectureReview = () => {
    const navigate = useNavigate();
    const { wellnessLectureId } = useParams();
    const [wellnessLecture, setWellnessLecture] = useState<IWellnessLectureDetailWebResponseV2>();
    const [uploadedUrls, setUploadedUrls] = useState<Array<string>>([]);
    const [content, setContent] = useState<string | undefined>(undefined);
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (!isLogin()) {
            navigate("/login")
            return;
        }
        if (!wellnessLectureId) return;

        getWellnessLectureDetailByWellnessLectureId(wellnessLectureId)
            .then(res => setWellnessLecture(res.data))
            .catch(err => console.error('err', err))

    }, [])


    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!wellnessLectureId) return;
        if (!rating) return message.error("별점을 입력해주세요.");
        if (!content) return message.error("후기를 작성해주세요.");

        const params = {
            rating: rating,
            content: content,
            wellnessLectureId: Number(wellnessLectureId),
            reviewCategoryList: [],
            imageUrlList: uploadedUrls,
            isPrivate: isPrivate
        }
        createWellnessLectureReview(params)
            .then(res => {
                if (res.data === true) {
                    navigate(-1);
                }
                else {
                    message.error('이미 리뷰를 작성했습니다.');
                }
            })
            .catch(err => console.error('err', err))
    }

    return <>
        <TopBarButton onClickCloseBtn={() => setIsShowModal(true)} />
        <div style={{ padding: 20 }}>
            {wellnessLecture && <>
                <div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ width: 56, height: 56, display: 'inline-block' }}>
                            <img src={wellnessLecture.wellnessLectureImageUrl || `https://img.positivehotel.io/2024/12/17/LectureDetailDefault.webp`} alt="수업사진" width={'100%'} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                        </div>
                        <div className="body/b1 secondary/s500" style={{ marginTop: 10 }}>{dayjs(wellnessLecture.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} · {wellnessLecture.teacherName} 코치</div>
                        <div className="sub-title/st4" style={{ marginTop: 8 }}>{wellnessLecture.wellnessLectureName}<br />어떠셨나요?</div>
                    </div>

                    <div style={{ marginTop: 32, textAlign: 'center' }}>
                        <div style={{ marginTop: 24 }}>
                            <Rate
                                style={{ fontSize: 48 }}
                                value={rating}
                                onChange={(value) => setRating(value)} />
                        </div>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <TextArea
                            showCount
                            maxLength={300}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setContent(e.target.value)}
                            placeholder="좋았던 경험, 혹은 아쉬웠던 점이 있다면 편하게 말씀해주세요."
                            value={content}
                            style={{ height: 148, resize: 'none' }}
                        />
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <ImageUploader setUploadedUrls={setUploadedUrls} />
                    </div>

                    <div style={{ marginTop: 32, color: '#828F9B', fontSize: 14 }}>

                        <Flex style={{ alignItems: 'center', cursor: 'pointer' }} gap={8} onClick={() => setIsPrivate(!isPrivate)}>
                            {isPrivate ? <CircleCheck width={16} height={16} stroke="#ffffff" fill="#000000" />
                                : <CircleCheck width={16} height={16} stroke="#000000" fill="#ffffff" />}
                            <div className="body/b2 secondary/s800">
                                코치 비공개로 작성하기
                            </div>
                        </Flex>
                    </div>

                    <div style={{ marginTop: 24 }}>
                        <Button type="primary"
                            style={{ width: '100%', height: '50px', fontSize: 14, fontWeight: 600 }}
                            onClick={handleClick}
                        >작성 완료</Button>
                    </div>
                    <br /><br /><br />
                </div>
            </>}
        </div>
        <Modal
            centered
            open={isShowModal}
            styles={{ footer: { margin: 0 } }}
            closeIcon={<Close width={24} height={24} stroke="var(--secondarys400)" />}
            onCancel={() => setIsShowModal(false)}
            footer={null}>
            <div>
                <div className="sub-title/st4" style={{ paddingBlock: 6, textAlign: 'center' }}>작성 취소</div>
                <div className="body/b1 secondary/s800" style={{ textAlign: 'center' }}>글을 저장하지 않고 나가시겠습니까?<br />작성한 글은 저장되지 않습니다.</div>
            </div>
            <Flex gap={16} style={{ marginTop: 16 }}>
                <Button color="default" variant="outlined" style={{ flex: 1, height: 43 }} onClick={() => setIsShowModal(false)}>돌아가기</Button>
                <Button color="default" variant="solid" style={{ flex: 1, height: 43 }} onClick={() => navigate(-1)}>작성 취소</Button>
            </Flex>
        </Modal>
    </>
}

export default CreateWellnessLectureReview;