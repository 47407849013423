import axios from "axios";
import { getLocalAccessToken, removeUser, setUser } from ".";
import { setUrl } from "./Url";
import { message } from "antd";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

instance.interceptors.request.use((config: any) => {
    const token = getLocalAccessToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use(
    (res) => {
        const accessToken = res.headers["authorization"];
        if (accessToken) {
            setUser(accessToken)
        }
        if (res?.data?.errors) {
            const error = res.data.errors[0];
            if (error.extensions.status === 401 || error.extensions.status === 403) {
                return Promise.reject(error);
            }
        }
        return res;
    },
    async (err) => {
        if (!err.response) {
            console.error("response is not found.  err: ", err);
        } else { console.error("err: ", err); }
        if (err.response?.status === 400 && err.response?.data?.errorType && err.response?.data?.errorType === 'ALL_READY_REGISTERED') {
            message.error(`이미 가입된 전화번호 입니다. \n ${err.response?.data.social}로 다시 시도해 보세요.`)
        }
        if (err.response.status === 401) {
            removeUser();
            setUrl(window.location.pathname)
            Promise.reject(err).finally(() => { window.location.href = "/login"; });
        }
        return Promise.reject(err);
    }
);
export default instance;