import { useNavigate, useSearchParams } from "react-router-dom"
import { TopBarButton } from "./topBarButton"
import { useEffect, useState } from "react"
import { useGetNickName } from "../model"
import { Button, Divider, Flex, Input, Upload, message } from "antd"
import { ReactComponent as Camera } from "../../../assets/images/icon/camera.svg"
import { ReactComponent as Img } from "../../../assets/images/icon/img.svg"
import { ReactComponent as CircleClose } from "../../../assets/images/icon/circle-close.svg"
import { getLocalAccessToken } from "../../../service"
import { useCreateFeedWeb } from "./model"
import { useQueryClient } from "@tanstack/react-query"
const { TextArea } = Input;

const CreateFeed = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const initForm = searchParams.get("initForm");
    const placeholder = searchParams.get("placeholder");
    const { data: nickName, isLoading } = useGetNickName();
    const [uploadedUrls, setUploadedUrls] = useState<Array<string>>([]);
    const [content, setContent] = useState<string>("");

    const createNickNameWeb = useCreateFeedWeb((res: any) => {
        if (res.data) {
            message.success("피드 생성에 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['nickName'] })
            navigate('/community')
        }
    })

    useEffect(() => {
        if (!isLoading) {
            if (!nickName) return navigate(-1);
        }
    }, [isLoading])

    const handleChange = ({ fileList: newFileList }: any) => {
        const successfulUploads = newFileList
            .filter((file: any) => file.status === 'done' && file.response)
            .map((file: any) => file.response);

        setUploadedUrls(successfulUploads);
    };

    const onClickUploadFeedButton = () => {
        createNickNameWeb.mutate({
            content,
            imageUrlList: uploadedUrls
        })
    }

    return <>
        <TopBarButton onClickCloseBtn={() => { navigate(-1) }} />
        <div style={{ padding: 20 }}>
            <div className="sub-title/st2">{nickName}</div>
            <TextArea
                variant="borderless"
                autoSize={{ minRows: 3, maxRows: 20 }}
                placeholder={placeholder ? placeholder : '요즘 발견한 나만의 웰니스 루틴은?'}
                maxLength={3000}
                style={{ padding: 0, marginTop: 10 }}
                onChange={(e) => setContent(e.target.value)}
                value={content}
            />

            <Flex style={{ overflow: 'scroll' }}>
                {uploadedUrls.map((imageUrl: string) => {
                    return <div style={{ position: 'relative', marginTop: 12 }}>
                        <div style={{ position: 'absolute', right: 19, top: 7 }}>
                            <Button
                                type="text"
                                icon={<CircleClose width={20} height={20} stroke="#ffffff" />}
                                onClick={() => setUploadedUrls(uploadedUrls.filter((url: string) => url !== imageUrl))}></Button>
                        </div>
                        <img src={imageUrl} alt="업로드된 이미지" width={'100%'} style={{ width: 162, height: 203, borderRadius: 4, marginRight: 12, objectFit: 'cover' }} />
                    </div>
                })}
            </Flex>
        </div>
        <div style={{ position: 'fixed', bottom: 0, width: '100%', maxWidth: '480px' }}>
            <Divider style={{ margin: "0" }} />
            <Flex style={{ marginBottom: 20, padding: 12, justifyContent: 'space-between' }}>
                <Flex style={{ gap: 10 }}>
                    <Upload
                        name='multipartFile'
                        action={`${process.env.REACT_APP_API_DOMAIN}/s3/upload`}
                        headers={{ "Authorization": `Bearer ${getLocalAccessToken()}` }}
                        withCredentials={true}
                        itemRender={() => <></>}
                        onChange={handleChange}
                        multiple
                    >
                        <Button type="text" icon={<Img />}></Button>
                    </Upload>

                    <Upload>
                        <Button type="text" icon={<Camera />}></Button>
                    </Upload>
                </Flex>
                <Flex style={{ alignItems: 'center' }} gap={10}>
                    <div className="caption/c1 secondary/s400">100/400</div>
                    <Button color="default" variant="solid" onClick={onClickUploadFeedButton}>게시</Button>
                </Flex>
            </Flex>
        </div>
        {initForm}
    </>
}

export default CreateFeed