import api from './Api'

export enum EProgram {
    id = 'id',
    isDisplay = 'isDisplay',
    title = 'title',
    description = 'description',
    isBooking = 'isBooking',
    isPaid = 'isPaid',
    isOnline = 'isOnline',
    onlineUrl = 'onlineUrl',
    roadNameAddress = 'roadNameAddress',
    locationName = 'locationName',
    thumbnailImageUrl = 'thumbnailImageUrl',
    detailImageUrlList = 'detailImageUrlList',
    detailHtml = 'detailHtml',
    notificationHtml = 'notificationHtml',
    refundNotiHtml = 'refundNotiHtml',
    programPeriod = 'programPeriod',
    bookingPeriod = 'bookingPeriod',
    createDateTime = 'createDateTime',
    bookingCnt = 'bookingCnt',
    programEventOutlineList = 'programEventOutlineList',
    programTicketList = `programTicketList`,
    programSelectOptionList = 'programSelectOptionList',
    programSubjectOptionList = 'programSubjectOptionList',
}

export enum EProgramEventOutline {
    id = 'id',
    day = 'day',
    startTime = 'startTime',
    programEventList = 'programEventList',
}

export enum EProgramEvent {
    id = 'id',
    targetDateTime = 'targetDateTime',
    maxBookingCnt = 'maxBookingCnt',
    isActive = 'isActive',
}

export enum EProgramTicket {
    id = 'id',
    name = 'name',
    description = 'description',
    payAmount = 'payAmount',
}

export enum EProgramSelectOption {
    id = 'id',
    idx = 'idx',
    name = 'name',
    isRequired = 'isRequired',
    description = 'description',
    programSelectOptionItemList = 'programSelectOptionItemList',
}
export enum EProgramSelectOptionItem {
    id = 'id',
    name = 'name',
}

export enum EProgramSubjectOption {
    id = 'id',
    idx = 'idx',
    name = 'name',
    description = 'description',
    isRequired = 'isRequired',
}

const defaultFieldListMap: IProgramField = {
    program: [EProgram.id, EProgram.isDisplay, EProgram.title, EProgram.description, EProgram.isBooking, EProgram.isPaid, EProgram.isOnline, EProgram.onlineUrl, EProgram.roadNameAddress, EProgram.locationName, EProgram.thumbnailImageUrl, EProgram.detailImageUrlList, EProgram.detailHtml, EProgram.notificationHtml, EProgram.refundNotiHtml, EProgram.programPeriod, EProgram.bookingPeriod, EProgram.createDateTime, EProgram.bookingCnt, EProgram.programEventOutlineList, EProgram.programTicketList, EProgram.programSelectOptionList, EProgram.programSubjectOptionList],
    programEventOutlineList: [EProgramEventOutline.id, EProgramEventOutline.day, EProgramEventOutline.startTime, EProgramEventOutline.programEventList],
    [EProgramEventOutline.programEventList]: [EProgramEvent.id, EProgramEvent.targetDateTime, EProgramEvent.maxBookingCnt, EProgramEvent.isActive],
    programTicketList: [EProgramTicket.id, EProgramTicket.name, EProgramTicket.description, EProgramTicket.payAmount],
    programSelectOptionList: [EProgramSelectOption.id, EProgramSelectOption.idx, EProgramSelectOption.name, EProgramSelectOption.isRequired, EProgramSelectOption.description, EProgramSelectOption.programSelectOptionItemList],
    [EProgramSelectOption.programSelectOptionItemList]: [EProgramSelectOptionItem.id, EProgramSelectOptionItem.name],
    programSubjectOptionList: [EProgramSubjectOption.id, EProgramSubjectOption.idx, EProgramSubjectOption.name, EProgramSubjectOption.description, EProgramSubjectOption.isRequired],
}

const transformData = (inputFields: IProgramField) => {
    const fieldListMap: Record<string, string[]> = {};
    let queryFieldListMap: string = '';
    const keys = Object.keys(inputFields)

    Object.entries(inputFields).forEach(([key, values]) => {
        const filteredValues = values.filter((value: string) => !keys.includes(value));
        if (filteredValues.length > 0) {
            fieldListMap[key] = filteredValues;
        }
        queryFieldListMap.includes(key)
            ? queryFieldListMap = queryFieldListMap.replace(key, `${key} { ${values.join(' ')} }`)
            : queryFieldListMap += values.join(' ')
    });
    return { queryFieldListMap, fieldListMap };
};

export const getProgramById = (id: number, responseQuery?: string) => {
    const query = `query GetProgramWebById($id:ID!) { getProgramWebById(id:$id) { ${responseQuery} } }`
    return api.post<IGraphqlResponse<{
        getProgramWebById: IProgramWebResponse & {
            programTicketList: Array<IProgramTicket>,
            programEventList: Array<IProgramEvent>,
            myProgramBooking: IProgramBooking,
            programEventOutlineList: Array<IProgramWebEventOutline>,
            programSelectOptionList: Array<IProgramSelectOption>,
            programSelectOptionItemList: Array<IProgramSelectOptionItem>,
            programSubjectOptionList: Array<IProgramSubjectOption>
        }
    }>>(`/graphql`, { query, variables: { id } })
};

export const getProgramList = (programField: IProgramField = defaultFieldListMap) => {
    const { queryFieldListMap, fieldListMap } = transformData(programField)
    const query = `query GetProgramList($fieldListMap: JSON) { getProgramList(fieldListMap:$fieldListMap) { ${queryFieldListMap} } }`
    return api.post<IGraphqlResponse<{ getProgramList: IProgramForList[] }>>(`/graphql`, { query, variables: { fieldListMap } })
};

export const createProgramBookingByMemberIdAndCreateProgramBookingWebRequest = (createProgramBookingWebRequest: CreateProgramBookingWebRequest) => {
    const query = `mutation CreateProgramBookingByMemberIdAndCreateProgramBookingWebRequest($createProgramBookingWebRequest: CreateProgramBookingWebRequest) {
        createProgramBookingByMemberIdAndCreateProgramBookingWebRequest(createProgramBookingWebRequest: $createProgramBookingWebRequest)
    }`
    return api.post<IGraphqlResponse<{ createProgramBookingByMemberIdAndCreateProgramBookingWebRequest: boolean }>>(`/graphql`, { query, variables: { createProgramBookingWebRequest } });
};

export const getProgramBookingWebByProgramBookingId = (id: number, responseQuery?: string) => {
    const query = `query getProgramBookingWebByProgramBookingId($programBookingId:ID!) { getProgramBookingWebByProgramBookingId(programBookingId: $programBookingId) { ${responseQuery} } }`
    return api.post<IGraphqlResponse<{ getProgramBookingWebByProgramBookingId: IProgramBookingWebResponse }>>('/graphql', { query, variables: { programBookingId: id } })
}

export const cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest = (cancelProgramBookingStatusRequest: ICancelProgramBookingStatusRequest) => {
    const query = `mutation CancelProgramBookingStatusWebByCancelProgramBookingStatusRequest($cancelProgramBookingStatusRequest: CancelProgramBookingStatusRequest) {
        cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest(cancelProgramBookingStatusRequest: $cancelProgramBookingStatusRequest)
    }`
    return api.post<IGraphqlResponse<{ cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest: boolean }>>(`/graphql`, { query, variables: { cancelProgramBookingStatusRequest } });
};


export const getProgramListWeb = (responseQuery?: string) => {
    const query = `query GetProgramListWeb { getProgramListWeb { ${responseQuery} } }`
    return api.post<IGraphqlResponse<{
        getProgramListWeb: Array<IProgramWebResponse> & {
            programTicketList: Array<IProgramTicket>,
            programEventList: Array<IProgramEvent>,
            myProgramBooking: IProgramBooking,
            programEventOutlineList: Array<IProgramWebEventOutline>,
            programSelectOptionList: Array<IProgramSelectOption>,
            programSelectOptionItemList: Array<IProgramSelectOptionItem>,
            programSubjectOptionList: Array<IProgramSubjectOption>
        }
    }>>(`/graphql`, { query })
};