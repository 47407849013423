import { useLocation } from "react-router-dom";
import { TopBar } from "../../../../../widgets";
import { Divider, Flex } from "antd";
import { moneyStringFormatByNumber } from "../../../../../utils";

const ProgramBookingPayInfo = () => {
    const location = useLocation();
    const programBookingTicketWebResponseList = location.state.programBookingTicketWebResponseList;

    console.log('programBookingTicketWebResponseList', programBookingTicketWebResponseList)

    return <>
        <TopBar title="결제 금액" rightIcon={false} />
        <div style={{ margin: '32px 20px' }}>
            {programBookingTicketWebResponseList.map((bookingTicket: IProgramBookingTicketWebResponse) => <Flex justify="space-between" style={{ marginTop: 16 }}>
                <div className="body/b2">{bookingTicket.programTicketName}</div>
                <div style={{ textAlign: 'right' }}>
                    <div className="body/b2">{moneyStringFormatByNumber(bookingTicket.programTicketPayAmount)} 원 x {bookingTicket.programBookingTicketCount}</div>
                    <div className="sub-title/st2">{moneyStringFormatByNumber(bookingTicket.programBookingTicketPrice)} 원</div>
                </div>
            </Flex>)}

            <Divider style={{ borderBlockStart: '1px solid var(--secondarys500)', marginBlock: 30 }} />
            <Flex justify="space-between" style={{ marginTop: 16 }}>
                <div className="body/b2">총 결제금액</div>
                <div style={{ textAlign: 'right' }}>
                    <div className="sub-title/st2">{moneyStringFormatByNumber(location.state.totalPayAmount)} 원</div>
                </div>
            </Flex>

        </div>
    </>
}
export default ProgramBookingPayInfo