import { Button, Divider, Flex } from "antd"
import dayjs from "dayjs"
import { ReactComponent as ArrowRight } from "../../../../../assets/images/icon/arrow-right.svg"
import { useNavigate } from "react-router-dom"

interface IProps {
    reservationList: Array<IGetMyReservationHistoryResponse>
}

const CancelReservation = ({ reservationList }: IProps) => {
    const navigate = useNavigate();

    return <>
        {reservationList.length === 0 ? <>
            <div style={{ marginTop: 250, textAlign: 'center' }}>
                <div className="body/b2 secondary/s800">취소한 프로그램이 없습니다.</div>
                <div style={{ marginTop: 8 }}>
                    <Button color="default" variant="outlined" onClick={() => navigate('/explore')}> 프로그램 둘러보기</Button>
                </div>
            </div>
        </> : reservationList.map((reservation: IGetMyReservationHistoryResponse, idx: number) => <>
            <div
                key={idx}
                style={{ margin: '20px 0', cursor: 'pointer' }}
                onClick={() => navigate(`/lecture/${reservation.wellnessLectureId}`)}
            >
                <Flex gap={16}>
                    <div style={{ width: 80, height: 80 }}>
                        <img src={reservation.imageUrl} alt="수업사진" width={'100%'} height={'100%'} style={{ borderRadius: 4, objectFit: 'cover' }} />
                    </div>
                    <div style={{ flex: 1 }}>
                        <div className="sub-title/st3">
                            {reservation.title}
                        </div>

                        <div className="body/b1 secondary/s500" style={{ marginTop: 5 }}>
                            <div>{dayjs(reservation.startDateTime).format('YYYY.MM.DD (dd) HH:mm')}</div>
                            {(reservation.teacherName != null && reservation.teacherName !== '') ? reservation.teacherName + ' 코치 · ' : ''} {reservation.yogaCenterName} {reservation.room}
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            {reservation.isPaid ?
                                <div className="caption/c1 error/e600" style={{ padding: '4px 8px', backgroundColor: 'var(--errore100)', display: 'inline-block', borderRadius: 4 }}>환불 완료</div>
                                : dayjs().isBefore(dayjs(reservation.startDateTime)) ?
                                    <Button type="text" className="sub-title/st1 secondary/s600">다시 예약 <ArrowRight width={12} stroke="var(--secondarys400)" /></Button> :
                                    <div className="caption/c1 secondary/s800" style={{ padding: '4px 8px', backgroundColor: 'var(--secondarys100)', display: 'inline-block', borderRadius: 4 }}>취소 완료</div>}
                        </div>
                    </div>
                </Flex>
            </div>
            <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: 0 }} />
        </>
        )}
    </>
}

export default CancelReservation