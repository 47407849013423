import { Carousel } from "antd";
import dayjs from "dayjs";
import { Fragment, useEffect, useRef } from "react";
import { ReactComponent as Ellipse } from "../../assets/images/ellipse.svg"

interface IProps {
    selectedDate: string
    onClick: Function
    reservedDateList: Array<string>
}
const getPreviousSundayOneMonthAgo = () => {
    let date = dayjs().subtract(1, "month");

    while (date.day() !== 0) {
        date = date.subtract(1, "day");
    }
    return date
};

const getPreviousSundayOneMonthAfter = () => {
    let date = dayjs().add(1, "month");

    while (date.day() !== 6) {
        date = date.subtract(1, "day");
    }
    return date
};


const DateSelectorCarousel = ({ reservedDateList, selectedDate, onClick }: IProps) => {
    const carouselRef = useRef<any>(null);

    const getMonthDaysForToday = () => {
        let days = [];
        const daysListForSeven = [];
        for (let date = getPreviousSundayOneMonthAgo(); date.isBefore(getPreviousSundayOneMonthAfter()) || date.isSame(getPreviousSundayOneMonthAgo(), 'day'); date = date.add(1, 'day')) {
            const dayObject = {
                day: date.format('ddd'),
                date: date.date(),
                dayjs: date.format("YYYY-MM-DD")
            };
            days.push(dayObject);
            if (days.length === 7) {
                daysListForSeven.push(days);
                days = [];
            }
        }
        if (days.length > 0) {
            while (days.length < 7) {
                days.push(undefined)
            }
            daysListForSeven.push(days);
        }
        return daysListForSeven;
    };

    const clickDate = async (day: string) => {
        onClick(day)
    }

    useEffect(() => {
        carouselRef.current?.goTo(4);
    }, [])

    return (<>
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }} >
            <Carousel ref={carouselRef} dots={false} infinite={false}>
                {getMonthDaysForToday().map((days, idx: number) => <Fragment key={idx}>
                    <div style={{ display: 'flex' }}>
                        {days.map((day, idx) => <Fragment key={idx}>
                            <div
                                onClick={() => day && clickDate(day.dayjs)}
                                style={{
                                    gap: "6px",
                                    cursor: "pointer",
                                    width: "16.6%",
                                    paddingBlock: "16px",
                                    textAlign: 'center'
                                }}>
                                {day && <>
                                    <div className="sub-title/st1" style={{ display: 'block', color: "var(--secondarys500)" }}>
                                        {day.dayjs === dayjs().format("YYYY-MM-DD")
                                            ? <div>오늘</div>
                                            : <div className="secondary/s500">{day.day}</div>}
                                    </div>
                                    <div style={{ display: 'inline-block' }}>
                                        <div className="body/b3" style={{ textAlign: "center", color: selectedDate === day.dayjs ? 'black' : "var(--secondarys800)", backgroundColor: selectedDate === day.dayjs ? "#FFF743" : undefined, borderRadius: "45%", width: '36px', height: '36px', gap: "6px", cursor: "pointer", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {day.date}
                                            {reservedDateList.includes(day.dayjs) && <div style={{ lineHeight: '4px' }}>
                                                <Ellipse fill={selectedDate === day.dayjs ? '#000000' : '#D9D9D9'} />
                                            </div>}
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </Fragment>
                        )}
                    </div>
                </Fragment>
                )}
            </Carousel>
        </div>
    </>)
}
export { DateSelectorCarousel }