import { Button, Flex, Input, Modal, message } from "antd";
import "./index.css"
import dayjs from "dayjs";
import { useCreateNickNameWeb } from "../../model";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

interface IProps {
    isOpen: boolean;
    setIsOpen: Function;
}

const NickNameBottomSheet = ({ isOpen, setIsOpen }: IProps) => {
    const queryClient = useQueryClient();
    const contentHeight = 250
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;

    const [nickName, setNickName] = useState<string>('');

    const createNickNameWeb = useCreateNickNameWeb((res: any) => {
        if (res.data) {
            message.success("닉네임 설정에 성공했습니다.")
            setIsOpen(false)
            queryClient.invalidateQueries({ queryKey: ['nickName'] })
        }
    })

    const onClickNickNameCreateButton = () => {
        setIsOpen(false)
        createNickNameWeb.mutate(nickName)
    }

    return <>
        <Modal transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight}px)`, padding: 0, margin: 0 }}
            styles={{ content: { minWidth: minWidth, height: `${contentHeight}px`, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px' } }}
            open={isOpen} closeIcon={null} footer={null} onCancel={() => setIsOpen(false)}>
            <div className="title/t1" style={{ color: '#111111', marginBottom: 'var(--space20)' }}>닉네임 설정</div>

            <div>커뮤니티 이용을 위해 닉네임 설정이 필요합니다.</div>

            <Input
                value={nickName}
                onChange={(e) => setNickName(e.target.value)}
                placeholder="3글자 이상 입력"
            >
            </Input>

            <div style={{ paddingTop: '20px' }}>
                <Button
                    color="default"
                    variant="solid"
                    className="sub-title/st3 base/white"
                    style={{ width: '100%', height: '51px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                    onClick={() => onClickNickNameCreateButton()}>
                    닉네임 설정
                </Button>
            </div>

        </Modal >
    </>
}

export { NickNameBottomSheet };


