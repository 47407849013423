import { useQuery } from "@tanstack/react-query";
import { getFeedWebByFeedId } from "../../../service/feed";

const useGetFeedWebByFeedId = (feedId: number, responseQuery?: string) => {
    const query = useQuery({
        queryKey: ['feedWebByFeedId'],
        queryFn: () => getFeedWebByFeedId(feedId, responseQuery),
        select: (res) => res.data.data.getFeedWebByFeedId,
    });

    return query
}


export { useGetFeedWebByFeedId }