import { TicketSelectCard } from "../../shared";
import TicketSkeleton from "../../components/skeleton/ticket/ticketSkeleton";
import { TopBar } from "../../widgets";
import { useNavigate } from "react-router-dom";
import { getAllWellnessTicketGroupAndWellnessTicketNameWithCenterInfoListByCompanyId } from "../../service/wellnessTicketGroup";
import { useQuery } from "@tanstack/react-query";

const WellnessTicketGroup = () => {
    const navigate = useNavigate();
    const companyId = 1;

    const { data: wellnessTicketGroup, isPending } = useQuery({
        queryKey: ['wellnessTicketGroup', 1], // Query key with company ID for uniqueness
        queryFn: async () => {
            const response = await getAllWellnessTicketGroupAndWellnessTicketNameWithCenterInfoListByCompanyId(1);
            return response.data;
        },
        enabled: !!companyId,
    });

    return <>
        {isPending && <TicketSkeleton />}
        <TopBar title="정기권 선택" />
        <div style={{ padding: '24px var(--space20)' }}>
            {wellnessTicketGroup
                ?.filter(q => q.isDisplay)
                ?.map(q => <TicketSelectCard key={q.id} title={q.title} description={q.description} onClick={() => { navigate(`/wellness-ticket-group/${q.id}`) }} />)}
        </div>
    </>
}
export { WellnessTicketGroup }