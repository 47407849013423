import { combineReducers } from 'redux';
import initYogaCenterSelector from './initYogaCenterSelector'
import selectedYogaCenterAndDate from './selectedYogaCenterAndDate';
import programBooking from './programBooking';

const rootReducer = combineReducers({
    selectedYogaCenterAndDate,
    initYogaCenterSelector,
    programBooking
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;