import { Link, useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as ArrowRight } from '../../../../assets/images/icon/arrow-right.svg'
import { ReactComponent as KakaoChannel } from '../../../../assets/images/icon/kakao-channel.svg'
import { Button, Divider, Flex } from "antd"
import { MemberBenefitInfo, BasicInfoRow } from "../../../../shared"
import { setUrl } from "../../../../service"


const MyBeforeLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const LonginLinkonClick = () => {
        setUrl(location.pathname);
    }
    return (
        <>
            <div style={{ paddingInline: 'var(--space20)' }}>
                <div style={{ marginTop: 43 }}>
                    <Link
                        to="/login"
                        className="sub-title/st4 base/black"
                        style={{ textDecoration: 'none', display: "flex", alignItems: "center", gap: 4 }}
                        onClick={LonginLinkonClick}>
                        로그인 · 회원가입 <ArrowRight width={16} height={16} stroke="#AEAEAE" />
                    </Link>
                </div>
                <Flex style={{ justifyContent: 'space-between', textAlign: 'left', marginTop: 24 }}>
                    <div style={{ width: '100%' }}>
                        <MemberBenefitInfo title="멤버십" value={"-"} to={'/member-ship?grade=2'} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <MemberBenefitInfo title={<>포인트<span style={{ lineHeight: "16px", padding: "0px 3px" }}>·</span> 쿠폰</>} value={"-"} to={'/my/reward'} />
                    </div>
                </Flex>
                <div style={{ marginTop: 20 }}>
                    <BasicInfoRow title={"정기권 구매"} onClick={() => navigate("/wellness-ticket-group")} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow title={"공지사항"} onClick={() => navigate("/notice")} />
                    <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                    <BasicInfoRow title={"이벤트"} onClick={() => navigate("/event")} />
                </div>
            </div>
            <div style={{ position: 'absolute', bottom: 80, width: '100%' }}>
                <Divider style={{ borderBlockStart: '1px solid var(--secondarys100)', margin: '0' }} />
                <div className="body/b2 secondary/s800" style={{ padding: 24, textAlign: 'center' }}>
                    <div>궁금한 점이 있으신가요?</div>
                    <div>카카오 채널을 통해 문의주시면 빠르게 답변드리겠습니다.</div>
                    <div style={{ marginTop: 20 }}>
                        <Button icon={<KakaoChannel />} style={{ padding: 18 }} onClick={() => window.open('https://pf.kakao.com/_xmWxbYG/chat')}></Button>
                    </div>
                </div>
            </div>
        </>
    )
}
export { MyBeforeLogin }