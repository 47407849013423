import { createReducer } from 'typesafe-actions';
import { ProgramBookingState, ProgramBookingAction } from './types';
import { UPDATE_PROGRAMBOOKING } from './actions';

const initialState: ProgramBookingState = {
    form: {},
    ticketList: [],
    optionList: {
        selectOptionList: [],
        subjectOptionList: []
    }
};

const programBooking = createReducer<ProgramBookingState, ProgramBookingAction>(initialState, {
    [UPDATE_PROGRAMBOOKING]: (state, action) => {
        return {
            ...state,
            form: action.payload.form,
            ticketList: action.payload.ticketList,
            optionList: action.payload.optionList
        }
    }
});

export default programBooking;