import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { Calendar, Flex } from 'antd';
import type { CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import { createStyles } from 'antd-style';
import classNames from 'classnames';
import { ReactComponent as ArrowLeft } from "../../../../../../assets/images/icon/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../../../../../assets/images/icon/arrow-right.svg";
import './index.css';

dayjs.extend(dayLocaleData);

interface IProps {
  onChange?: (date: string) => void
  onActiveDateList: Array<string>
  initDate?: string
}

const useStyle = createStyles(({ token, css, cx }) => {
  const weekend = css`
      color: var(--secondarys800);
      &.gray {
        opacity: 0.4;
      }
    `;
  return {
    wrapper: css`
        width: 100%;
      `,
    dateCell: css`
        position: relative;
        &:before {
          content: '';
          position: absolute;
          inset-inline-start: 0;
          inset-inline-end: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          max-width: 40px;
          max-height: 40px;
          background: transparent;
          transition: background-color 300ms;
          border-radius: ${token.borderRadiusOuter}px;
          border: 1px solid transparent;
          box-sizing: border-box;
        }
      `,
    today: css`
        &:before {
          border: 0.75px solid var(--secondarys500);
          // background: #fff96982;
        }
      `,
    disabled: css`
      &:before {
      }
    `,
    text: css`
        position: relative;
        z-index: 1;
        padding-top: 4px;
      `,
    current: css`
        color: var(--secondarys800);
        &:before {
          background: #fff969;
        }
        &:hover:before {
          background: #fff969;
          opacity: 0.8;
        }
        .${cx(weekend)} {
          color: #000000;
        }
      `,
    monthCell: css`
        width: 120px;
        color: ${token.colorTextBase};
        border-radius: ${token.borderRadiusOuter}px;
        padding: 5px 0;
        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      `,
    monthCellCurrent: css`
        color: ${token.colorTextLightSolid};
        background: ${token.colorPrimary};
        &:hover {
          background: ${token.colorPrimary};
          opacity: 0.8;
        }
      `,
    weekend,
  };
});

const WellnessProgramBookCalendar = ({ initDate = dayjs().format('YYYY-MM-DD'), onChange, onActiveDateList }: IProps) => {
  const [date, setDate] = useState<string>(initDate)
  const [selectedDate, setSelectedDate] = useState<string>(date);

  const { styles } = useStyle({ test: true });

  const [panelDateDate, setPanelDate] = React.useState<Dayjs>(dayjs());

  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
    setPanelDate(value);
    setDate(value.format('YYYY-MM-DD'))
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (date, info) => {
    if (!panelDateDate.isSame(date, 'month')) return null;

    const isWeekend = date.day() === 6 || date.day() === 0;
    const isPastDate = dayjs().isAfter(date, 'day');
    const isActiveDate = onActiveDateList.some(onActiveDateList => dayjs(onActiveDateList).isSame(date, 'date'));
    if (info.type === 'date') {
      return React.cloneElement(info.originNode, {
        ...info.originNode.props,
        className: classNames(styles.dateCell, {
          [styles.current]: dayjs(selectedDate).isSame(date, 'date'),
          [styles.today]: date.isSame(dayjs(), 'date'),
          [styles.disabled]: isPastDate,
        }),
        children: (
          <div className={styles.text}
            style={(isPastDate || !isActiveDate) ? { color: '#cdcdcd', cursor: 'auto', pointerEvents: 'none' } : {}}>
            <span
              className={classNames({
                [styles.weekend]: isWeekend,
                gray: !panelDateDate.isSame(date, 'month'),
              })}
              style={(isPastDate || !isActiveDate) ? { color: '#cdcdcd', cursor: 'auto', pointerEvents: 'none' } : {}}
            >
              {date.get('date')}
            </span>
            <div style={{ height: 4 }}></div>
          </div >
        ),
      });
    }

    return info.originNode;
  };

  const onDateChange: CalendarProps<Dayjs>['onSelect'] = (value, selectInfo) => {
    if (selectInfo.source === 'date') {
      const isActiveDate = onActiveDateList.some(onActiveDateList => dayjs(onActiveDateList).isSame(dayjs(value), 'date'));
      if (isActiveDate) {
        if (dayjs().add(-1, 'day').isBefore(value, 'day')) {
          setDate(value.format('YYYY-MM-DD'))
          setSelectedDate(value.format('YYYY-MM-DD'));
        }
      }
    }
  };

  useEffect(() => { onChange?.(selectedDate) }, [selectedDate]) // antd Form.Item에서 인식하기 위한 선언

  return <>
    <div className={styles.wrapper}>
      <Calendar
        locale={{ ...locale, lang: { ...locale.lang, shortWeekDays: ["일", "월", "화", "수", "목", "금", "토"] } }}
        fullscreen={false}
        headerRender={({ value, onChange }) => {
          return <>
            <div style={{ padding: 8 }}>
              <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                <ArrowLeft width={16} height={16} stroke={value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") ? "var(--secondarys400)" : '#cdcdcd'}
                  style={{ cursor: "pointer", padding: 'var(--space12)', pointerEvents: value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") ? 'auto' : 'none' }}
                  onClick={() => value.endOf("month") >= dayjs().add(-2, 'M').endOf("month") && onChange(value.clone().add(-1, 'month'))} />

                <div className="sub-title/st3">{`${value.year()}. ${value.month() + 1}`}</div>

                <ArrowRight width={16} height={16} stroke={value.endOf("month") <= dayjs().endOf("month") ? "var(--secondarys400)" : '#cdcdcd'}
                  style={{ cursor: "pointer", padding: 'var(--space12)', pointerEvents: value.endOf("month") <= dayjs().endOf("month") ? 'auto' : 'none' }}
                  onClick={() => value.endOf("month") <= dayjs().endOf("month") && onChange(value.clone().add(+1, 'month'))} />
              </Flex>
            </div>
          </>
        }}
        fullCellRender={cellRender}
        onPanelChange={onPanelChange}
        onSelect={onDateChange}
      />
    </div>
  </>
}

export default WellnessProgramBookCalendar