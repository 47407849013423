import { Navigate, useNavigate, useParams } from "react-router-dom"
import { TopBar } from "../../../widgets";
import dayjs from "dayjs";
import KakaoMap from "../../../components/map/kakao";
import { useState } from "react";
import { Carousel } from "../../../shared";
import { ReactComponent as ScheduleV2 } from "../../../assets/images/icon/scheduleV2.svg";
import { ReactComponent as LocationV2 } from "../../../assets/images/icon/locationV2.svg";
import { ReactComponent as ArrowDown } from "../../../assets/images/icon/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../../assets/images/icon/arrow-up.svg";
import { useGetProgramById } from "../book/firstStep/model";
import { Flex } from "antd";

const WellnessProgramDetail = () => {
    const navigate = useNavigate();
    const id = Number(useParams().id);
    const [isDetailOpen, setIsDetailOpen] = useState<boolean>(false);
    const responseQuery = "detailImageUrlList title isPaid description programPeriod detailHtml isPaid roadNameAddress locationName notificationHtml refundNotiHtml id programTicketList { name description payAmount } programEventList { targetDateTime } programEventOutlineList { startTime day } myProgramBookingList { status bookingDateTime }"
    const { data, isPending, isError } = useGetProgramById(id, responseQuery)

    if (data === undefined || isPending) return <div>데이터가 없습니다.</div>
    if (isNaN(id)) return <Navigate to={"/explore"} replace />

    return <>
        <TopBar title="프로그램 상세" rightIcon thema='dark' />
        <Carousel carouselList={data.detailImageUrlList?.map((q: string) => ({ imageUrl: q, linkUrl: '' }))} imgSize={{ x: 1, y: 1 }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: 20 }}>
            <div className="sub-title/st4">
                {data.title}
            </div>
            {data.isPaid && <div>
                {data.programTicketList?.sort((a, b) => a.payAmount - b.payAmount)[0].payAmount.toLocaleString()} -
            </div>}
            <div className="body/b2 secondary/s600">
                {!data.myProgramBooking ? '' :
                    data.myProgramBooking.status === 'BOOKING' ? '에약 대기중입니다' :
                        data.myProgramBooking.status === 'CANCEL' ? '에약 취소 되었습니다.' :
                            data.myProgramBooking.status === 'COMPLETE' ? '에약 확정되었습니다.' :
                                data.myProgramBooking.status === 'REFUND' ? '환불 완료되었습니다.' :
                                    ''}
            </div>
            <div className="body/b2 secondary/s600">
                {data.description}
            </div>
        </div>
        <div style={{ marginInline: 20, paddingBlock: '10px', }}>
            <div className="sub-title/st3" style={{ marginBottom: '20px' }}>
                프로그램 상세
            </div>
            <div style={{ display: 'flex', gap: '16px', paddingInline: '12px' }}>
                <ScheduleV2 width={24} height={24} stroke="var(--secondarys400)" fill="var(--secondarys400)" />
                <div>
                    <div className="sub-title/st3 secondary/s850" style={{ marginBottom: '2px' }}>
                        {data.programPeriod && dayjs(data.programPeriod[0]).format('YYYY.MM.DD') + ' - ' + dayjs(data.programPeriod[1]).format('YYYY.MM.DD')}
                    </div>
                    <div className="body/b2 secondary/s600">
                        {data.programEventOutlineList
                            .flatMap((i: IProgramWebEventOutline) => i.day)
                            .map((day: number) => <div>
                                <span style={{ marginRight: 5 }}>{['일', '월', '화', '수', '목', '금', '토'][day]}</span>
                                <span>{data.programEventOutlineList
                                    .filter((eventOutline: IProgramWebEventOutline) => eventOutline.day.includes(day))
                                    .map((eventOutline: IProgramWebEventOutline) => eventOutline.startTime)
                                    .join(', ')
                                }</span>
                            </div>)}
                    </div>
                </div>
            </div>
            <br />
            <div style={{ display: 'flex', gap: '16px', paddingInline: '12px' }}>
                <LocationV2 width={24} height={24} stroke="var(--secondarys400)" fill="white" />
                <div>
                    <div className="sub-title/st3 secondary/s850" style={{ marginBottom: '2px' }}>
                        {data.locationName}
                    </div>
                    <div className="body/b2 secondary/s600">
                        {data.roadNameAddress}
                    </div>
                </div>
            </div>
        </div>
        <div style={{ padding: 20 }}>
            <div className="sub-title/st3" style={{ marginTop: '12px', marginBottom: '16px' }}>
                프로그램 상세
            </div>
            <div>
                <div style={{ maxHeight: isDetailOpen ? '' : '686px', overflow: 'hidden', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.detailHtml }} />
                <div style={{ textAlign: 'center', marginTop: '16px' }}>
                    <div className="caption/c1 secondary/s600" style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => setIsDetailOpen(!isDetailOpen)}>
                        {isDetailOpen ? '접기' : '더보기'}
                    </div>
                    {isDetailOpen
                        ? <ArrowUp width={16} height={16} stroke="var(--secondarys400)" />
                        : <ArrowDown width={16} height={16} stroke="var(--secondarys400)" />}
                </div>
            </div>
        </div>

        {data.isPaid && <div style={{ padding: 20 }}>
            <div>
                <div className="sub-title/st3" style={{ marginBottom: 16 }}>티켓</div>
                {data.programTicketList?.map((ticket) => <div key={ticket.id}>
                    <Flex justify="space-between" style={{ padding: '12px 0', borderBottom: '1px solid var(--secondarys100)', alignItems: 'center' }} gap={16}>
                        <div>
                            <div className="sub-title/st3 secondary/s850">{ticket.name}</div>
                            <div className="body/b2 secondary/s600" style={{ marginTop: 5 }}>{ticket.description}</div>
                        </div>
                        <div className="sub-title/st3" style={{ minWidth: 100, textAlign: 'right' }}>{ticket.payAmount.toLocaleString()}원</div>
                    </Flex>

                </div>
                )}
            </div>
        </div>}
        <div style={{ padding: '12px 20px 20px' }}>
            <div className="sub-title/st3 secondary/s800" style={{ marginBottom: '16px' }}>
                위치
            </div>
            <div className="body/b3 secondary/s850" style={{ marginBottom: '16px' }}>
                {data.roadNameAddress + ', ' + data.locationName}
            </div>

            <KakaoMap lat={127.035349} lon={37.522466} style={{ borderRadius: 'var(--radius4)' }} />
        </div>
        <div style={{ padding: 20 }}>
            <div className="sub-title/st3 secondary/s850" style={{ marginBottom: '16px' }}>
                안내사항
            </div>
            <div className="body/b3 secondary/s850" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.notificationHtml }} />
        </div>
        <div style={{ padding: 20 }}>
            <div className="sub-title/st3 secondary/s850" style={{ marginBottom: '16px' }}>
                취소・환불 규정
            </div>
            <div className="body/b3 secondary/s850" style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: data.refundNotiHtml }} />
        </div>

        <div style={{ height: 70 }}></div>
        <div style={{ position: 'sticky', bottom: 0, textAlign: 'center', width: '100%', maxWidth: '480px', zIndex: 1, }} onClick={() => navigate(`/wellness-program/book/first-step/${data.id}`)}>
            <div className="sub-title/st3 base/white" style={{ backgroundColor: 'black', paddingBlock: 20, marginInline: '20px', borderRadius: 'var(--radius4)' }}>
                신청하기
            </div>
            <div style={{ height: '10px' }} />
        </div>
    </>
}

export default WellnessProgramDetail
