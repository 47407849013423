import { useEffect, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/images/icon/arrow-right.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deepLinkBridgeElseNewTap, isLogin } from "../../../utils";
import { getNoticePopup, getNotificationListByMethodStatusAndCreatedDateBetween, getNotificationUserAlarmBoxList, removeUser, requestNotificationReceiptOk, setUrl } from "../../../service";
import NotificationSkeleton from "../../../components/skeleton/notification/notificationSkeleton";
import { TopBar } from "../../../widgets";
import { CustomTabs } from "../../../shared";
import { items } from "./model";
import dayjs from "dayjs";

const Notification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [noticePopupList, setNoticePopupList] = useState<Array<INoticePopup>>([]);
    const [notificationList, setNotificationList] = useState<Array<INotification>>();
    const [isInitLoading, setIsIntLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!isLogin()) {
            setIsIntLoading(false);
            setUrl(location.pathname);
            return;
        }
        requestNoticePopup()
            .finally(() => { setIsIntLoading(false) });
    }, [])
    const getNotificationListByMethodStatusApi = async () => {
        await getNotificationListByMethodStatusAndCreatedDateBetween(undefined, dayjs().add(-30, 'd').format("YYYY-MM-DDTHH:mm:ss"), dayjs().format("YYYY-MM-DDTHH:mm:ss"))
            .then((resNotificationList) => {
                const filteredNotification = resNotificationList.data.filter((resNotificaion) => resNotificaion.notificationStatus === "ALARM")
                getNotificationUserAlarmBoxList(dayjs().add(-30, 'd').format("YYYY-MM-DDTHH:mm:ss"), dayjs().format("YYYY-MM-DDTHH:mm:ss"))
                    .then((res) => { setNotificationList(() => (([...filteredNotification, ...res.data.map((e) => ({ id: e.notificationDetailId, title: e.title, content: e.content, notificationMethodStatus: "", notificationStatus: "", notificationTemplateStatus: "", deepLinkUrlPath: "", createdDate: e.notificationAt, isNotificationV2: true, deepLinkUrl: e.deepLinkUrl, isReceipt: e.isReceipt }))].sort((a, b) => dayjs(b.createdDate).diff(dayjs(a.createdDate)))))) })
                    .catch((error) => { console.error("Error: ", error) })
            })
            .catch((error) => {
                removeUser()
                console.error("Error: ", error)
            })

    }
    const requestNoticePopup = async () => {
        await getNoticePopup()
            .then((res) => { setNoticePopupList(res.data.filter((i) => !i.isDelete).sort((a, b) => dayjs(b.createdDate).diff(dayjs(a.createdDate)))); })
            .catch((error) => {
                removeUser();
                console.error("Error: ", error);
            })
        await getNotificationListByMethodStatusApi()
    }

    const clickNavigationDetailPageBydeepLinkUrl = (deepLinkUrl?: string) => {
        if (!deepLinkUrl) return;
        if (process.env.REACT_APP_DOMAIN && deepLinkUrl.startsWith(process.env.REACT_APP_DOMAIN)) {
            navigate(deepLinkUrl.split(process.env.REACT_APP_DOMAIN)[1])
        } else {
            deepLinkBridgeElseNewTap(deepLinkUrl)
            getNotificationListByMethodStatusApi()
        }
    }
    const notificationOnClick = (notification: INotification) => {
        notification.isNotificationV2
            ? requestNotificationReceiptOk(notification.id.toString()).then(() => { clickNavigationDetailPageBydeepLinkUrl(notification.deepLinkUrl) })
            : notification.deepLinkUrlPath && navigate(notification.deepLinkUrlPath);
    }

    return <>
        {isInitLoading && <NotificationSkeleton />}
        <TopBar rightIcon={false} title="알림" />
        {!isLogin()
            ? <div style={{ height: '182px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 'var(--space20)' }}>
                <div>로그인 후 이용해 주세요.
                    <div style={{ fontSize: '13px', margin: '10px' }}>
                        <Link to={'/login'} style={{ color: '#777', textDecoration: 'none' }}>로그인 / 회원가입 하러 가기 <ArrowRight width={12} stroke="#000000" /></Link></div>
                </div>
            </div>
            : notificationList && <CustomTabs items={items(notificationList, noticePopupList, notificationOnClick)} />}
    </>

}
export { Notification }