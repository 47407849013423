import { useEffect, useState } from "react";
import { CustomTabs, GnbFooter } from "../../shared"
import { useGetNickName } from "./model"
import { NickNameBottomSheet } from "./ui/nickNameBottomSheet";
import RecommendFeed from "./ui/recommendFeed";
import CreateFeedForm from "./ui/CreateFeedForm";
import { message } from "antd";

const Community = () => {
    const { data: nickName, isLoading, isSuccess, isError } = useGetNickName();
    const [isShowNickNameBottomSheet, setIsShowNickNameBottomSheet] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoading) {
            if (isError) {
                message.error("서버에러")
            } else {
                setIsShowNickNameBottomSheet(!nickName)
            }

        }
    }, [isLoading])

    return <>
        <div className="sub-title/st5" style={{ padding: 20 }}>커뮤니티</div>
        <CustomTabs items={[
            {
                key: 0, label: '추천', children: <>
                    {nickName &&
                        <div style={{ padding: 20 }}>
                            <CreateFeedForm nickName={nickName} />
                        </div>}
                    <RecommendFeed />
                </>
            },
            { key: 1, label: '내가 쓴 글', children: <>예약확정</> },
        ]}
            defaultkey={0} />

        <GnbFooter activeButton='community' />
        <NickNameBottomSheet
            isOpen={isShowNickNameBottomSheet}
            setIsOpen={setIsShowNickNameBottomSheet}
        />
    </>
}

export { Community }