import { useNavigate, useParams } from "react-router-dom";
import { isLogin } from "../../../utils";
import { TopBar } from "../../../widgets";
import { useEffect, useState } from "react";
import { Button, Checkbox, Flex, Input, Rate, Tag, message } from "antd";
import ImageUploader from "../ui/ImageUploader";
import dayjs from "dayjs";
import { useGetProgramById } from "../../wellnessProgram/book/firstStep/model";

const { TextArea } = Input;

const reviewCategoryList = [
    { id: 1, title: "🕒 수업 시간을 잘 지켜요" },
    { id: 2, title: "😉 자세를 꼼꼼히 봐주세요" },
    { id: 3, title: "💙 친절하게 설명해요" },
    { id: 4, title: "‍🤸‍♂️ 난이도가 적절해요" },
]

const CreateProgramReview = () => {
    const navigate = useNavigate();
    const { programId } = useParams();
    const [wellnessLecture, setWellnessLecture] = useState<IWellnessLectureDetailWebResponseV2>();
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [uploadedUrls, setUploadedUrls] = useState<Array<string>>([]);
    const [content, setContent] = useState<string | undefined>(undefined);
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [isPrivate, setIsPrivate] = useState<boolean>(false);
    // const { data, isPending, isError } = useGetProgramById(Number(programId));

    useEffect(() => {
        if (!isLogin()) {
            navigate("/login")
            return;
        }
    }, [])

    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
    };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!programId) return;
        if (!rating) return message.error("별점을 입력해주세요.");
        if (!content) return message.error("후기를 작성해주세요.");

        const params = {
            rating: rating,
            content: content,
            programId: Number(programId),
            imageUrlList: uploadedUrls,
            isPrivate: isPrivate
        }
        // createWellnessLectureReview(params)
        //     .then(res => {
        //         if (res.data === true) {
        //             navigate(-1);
        //         }
        //         else {
        //             message.error('이미 리뷰를 작성했습니다.');
        //         }
        //     })
        //     .catch(err => console.error('err', err))
    }

    return <>
        <TopBar title="리뷰 작성" rightIcon={false} />
        <div style={{ padding: 20 }}>
            {wellnessLecture && <>
                <div>
                    <Flex style={{ padding: 12, alignItems: 'center', border: '1px solid #E8EBED', borderRadius: 4 }} gap={16}>
                        <div style={{ width: 56, height: 56 }}>
                            <img src={wellnessLecture.wellnessLectureImageUrl || `https://img.positivehotel.io/2024/12/17/LectureDetailDefault.webp`} alt="수업사진" width={'100%'} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                        </div>
                        <div>
                            <div style={{ fontSize: 16, lineHeight: '26px', fontWeight: 600 }}>{wellnessLecture.wellnessLectureName}</div>
                            <div style={{ fontSize: 12, lineHeight: '20px' }}>{dayjs(wellnessLecture.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} - {dayjs(wellnessLecture.wellnessLectureEndDateTime).format('HH:mm')} | {wellnessLecture.yogaCenterName}</div>
                            <div style={{ fontSize: 12, lineHeight: '20px' }}>{wellnessLecture.teacherName} 코치</div>
                        </div>
                    </Flex>

                    <div style={{ marginTop: 32, textAlign: 'center' }}>
                        <div style={{ color: '#828F9B', fontSize: 14 }}>별점</div>
                        <div style={{ marginTop: 24 }}>
                            <Rate
                                style={{ fontSize: 40 }}
                                value={rating}
                                onChange={(value) => setRating(value)} />
                        </div>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <div style={{ color: '#828F9B', fontSize: 14, textAlign: 'center' }}>오늘 수업은 어땠나요?</div>
                        <div style={{ marginTop: 24 }}>
                            {reviewCategoryList.map<React.ReactNode>((tag) => (
                                <Tag.CheckableTag
                                    key={tag.id}
                                    checked={selectedTags.includes(tag.title)}
                                    onChange={(checked) => handleChange(tag.title, checked)}
                                    style={{
                                        border: selectedTags.includes(tag.title) ? '1px solid #369AFF' : '1px solid #D2D6DB',
                                        backgroundColor: selectedTags.includes(tag.title) ? '#e6f7ff' : 'white',
                                        color: 'black',
                                        marginTop: 16, marginRight: 16
                                    }}
                                >
                                    <div style={{ padding: '4px 5px', lineHeight: '24px', fontSize: 14, fontWeight: 600 }}>{tag.title}</div>
                                </Tag.CheckableTag>
                            ))}
                        </div>
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <TextArea
                            showCount
                            maxLength={300}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setContent(e.target.value)}
                            placeholder="이번 수업에 대한 의견을 남겨주세요."
                            value={content}
                            style={{ height: 88, resize: 'none' }}
                        />
                    </div>

                    <div style={{ marginTop: 32 }}>
                        <ImageUploader setUploadedUrls={setUploadedUrls} />
                    </div>

                    <div style={{ marginTop: 32, color: '#828F9B', fontSize: 14 }}>
                        <Checkbox onChange={(e) => setIsPrivate(e.target.checked)}>비공개로 작성할래요</Checkbox>
                        <br />
                        <div style={{ marginLeft: 25 }}>작성하신 후기는 강사님께 전달되지 않습니다.</div>
                    </div>

                    <div style={{ marginTop: 24 }}>
                        <Button type="primary"
                            style={{ width: '100%', height: '50px', fontSize: 14, fontWeight: 600 }}
                            onClick={handleClick}
                        >작성 완료</Button>
                    </div>
                    <br /><br /><br />
                </div>
            </>}
        </div >
    </>
}

export default CreateProgramReview;