import dayjs from "dayjs";
import { TextBadge } from "../.."
import { ReactComponent as CircleCheck } from "../../../assets/images/icon/circle-check.svg";

interface IProps {
    wellnessTicket: any
    isSelect?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}
const WellnessTicketCardForWellnessTicketGroupDetail = ({ wellnessTicket, isSelect = false, onClick }: IProps) => {

    const formatDate = (date?: string, addDays: number = 0) => {
        if (!date) return dayjs().add(addDays, 'd').format('YYYY.MM.DD');
        return dayjs(date).add(addDays, 'd').format('YYYY.MM.DD');
    };


    return isSelect
        ? <div style={{ background: `linear-gradient(135deg, ${wellnessTicket.startColor}, ${wellnessTicket.endColor})`, padding: 'var(--space16)', borderRadius: 'var(--radius4)', color: wellnessTicket.textColor, marginBottom: 'var(--space20)' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    <div className="title/t2">{wellnessTicket.name}</div>
                    <div className="body/b1" style={{ marginTop: 'var(--space4)' }}> {`${formatDate(wellnessTicket.startDate)} ~ ${formatDate(wellnessTicket.expireDate, wellnessTicket.usableDate)} (${wellnessTicket.usableDate}일)`}</div>
                </div>
                <CircleCheck width={20} height={20} stroke={wellnessTicket.endColor} fill={wellnessTicket.textColor} />
            </div>
            <div style={{ marginTop: 'var(--space40)' }}>
                {wellnessTicket.price === wellnessTicket.discountedPrice
                    ? <div className="title/t4">{wellnessTicket.price.toLocaleString()}원</div>
                    : <div>
                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            {(wellnessTicket.discountType === 'PERCENT' && Number(wellnessTicket.discountedValue) > 0) && <div style={{ fontWeight: '600', fontSize: '14px', lineHeight: '24px' }}>{wellnessTicket.discountedValue}%</div>}
                            <div className="secondary/s700" style={{ marginLeft: (wellnessTicket.discountType === 'PERCENT' && Number(wellnessTicket.discountedValue) > 0) ? '4px' : '0px', textDecoration: 'line-through', fontSize: '14px', lineHeight: '24px' }}>{wellnessTicket.price.toLocaleString()}</div>
                        </div>
                        <div style={{ marginTop: '4px' }} className="title/t4">{wellnessTicket.discountedPrice.toLocaleString()}원</div>
                    </div>
                }
            </div>
        </div>
        : <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'var(--basewhite)', padding: 'var(--space16)', border: '1px solid var(--secondarys300)', borderRadius: 'var(--radius4)', marginBottom: 'var(--space16)', cursor: 'pointer' }} onClick={onClick}>
            <div>
                <div className="title/t2 base/black">
                    {wellnessTicket.name}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 'var(--space4)' }}>
                    <div className="body/b2 secondary/s700">{wellnessTicket.discountedPrice.toLocaleString()}원 </div>
                    {(wellnessTicket.discountType === 'PERCENT' && Number(wellnessTicket.discountedValue) > 0) && <TextBadge text={wellnessTicket.discountedValue + '%'} style={{ backgroundColor: 'var(--baseblack)', color: 'var(--basewhite)', marginLeft: 'var(--space4)' }} />}
                    {(wellnessTicket.discountType === 'FIXED' && Number(wellnessTicket.discountedValue) > 0) && <div className="body/b2 secondary/s400" style={{ marginLeft: '4px', textDecoration: 'line-through' }}>{wellnessTicket.price.toLocaleString()}</div>}
                </div>
            </div>
            <CircleCheck width={20} height={20} stroke={"#AEAEAE"} fill={'white'} />
        </div>
}

export { WellnessTicketCardForWellnessTicketGroupDetail }