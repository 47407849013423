import { Button, Flex, Modal, message } from "antd";
import "./index.css"
import { ReactComponent as Edit } from "../../../../assets/images/icon/edit.svg";
import { ReactComponent as Trash } from "../../../../assets/images/icon/trash.svg";
import { useState } from "react";
import { useCreateFeedClaimWeb } from "../recommendFeed/model";
import { useDeleteFeedCommentWeb } from "../../model";
import { useQueryClient } from "@tanstack/react-query";

interface IProps {
    feedCommentId: number
    isOpen: boolean
    setIsOpen: Function
}

interface IClaimData {
    id: number
    label: React.ReactNode
    value: number

}
const optionList: Array<IClaimData> = [
    {
        id: 1, label: <Flex gap={16} style={{ alignItems: 'center' }}>
            <Edit className="secondary/s400" style={{ width: 16 }} />
            <div className="body/b3 secondary/s800">수정</div>
        </Flex>, value: 1
    },
    {
        id: 2, label: <Flex gap={16} style={{ alignItems: 'center' }}>
            <Trash className="secondary/s400" style={{ width: 16 }} />
            <div className="body/b3 secondary/s800">삭제</div>
        </Flex>, value: 2
    },
]

const MyCommentBottomSheet = ({ feedCommentId, isOpen, setIsOpen }: IProps) => {
    const queryClient = useQueryClient();
    const contentHeight = 190
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;

    const deleteFeedCommentWeb = useDeleteFeedCommentWeb((res: any) => {
        if (res.data) {
            message.success("댓글 삭제 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
            setIsOpen(false)
        }
    })

    const clickOption = (id: number) => {
        if (id === 1) {
            // 수정
        } else if (id === 2) {
            deleteFeedCommentWeb.mutate(feedCommentId)
        }
    }
    return <>
        <Modal transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight - 50}px)`, padding: 0, margin: 0 }}
            styles={{ content: { minWidth: minWidth, height: `${contentHeight}px`, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px' } }}
            open={isOpen} closeIcon={null} footer={null} onCancel={() => {
                setIsOpen(false)
            }}>

            {optionList.map((claim: IClaimData) =>
                <Flex
                    justify="space-between"
                    style={{ padding: 'var(--space12) 0px var(--space12) var(--space12)', cursor: 'pointer' }}
                    onClick={() => { clickOption(claim.id) }}
                >
                    {claim.label}
                </Flex>)}
        </Modal >
    </>
}

export { MyCommentBottomSheet };


