import { useParams } from "react-router-dom";
import { useGetFeedWebByFeedId } from "./model";
import { TopBar } from "../../widgets";
import { Button, Divider, Flex, message, Input } from "antd";
import dayjs from "dayjs";
import { ReactComponent as More } from "../../assets/images/icon/more.svg"
import { ReactComponent as FillOff } from "../../assets/images/icon/fill=off.svg"
import { ReactComponent as FillOn } from "../../assets/images/icon/fill=on.svg"
import { ReactComponent as Bubble } from "../../assets/images/icon/bubble.svg"
import { useState } from "react";
import { FeedClaimBottomSheet } from "../community/ui/feedClaimBottomSheet";
import { useCreateFeedCommentLikeWeb, useCreateFeedCommentWeb, useCreateFeedLikeWeb, useDeleteFeedCommentLikeWeb, useDeleteFeedLikeWeb } from "../community/ui/recommendFeed/model";
import { useQueryClient } from "@tanstack/react-query";
import { MyCommentBottomSheet } from "../community/ui/myCommentBottomSheet";
const { TextArea } = Input;

const CommunityFeedDetail = () => {
    const queryClient = useQueryClient();
    const id = Number(useParams().id);
    const { data: feedDetail, isLoading, isSuccess, isError } = useGetFeedWebByFeedId(id, 'id requestMemberId nickName content imageUrlList lastUpdatedDate createdDate feedCommentList { id content memberId lastUpdatedDate feedCommentLikeList { id memberId lastUpdatedDate } nickName { nickName } } feedLikeList { id memberId lastUpdatedDate }');
    const [isOpenFeedClaimModal, setIsOpenFeedClaimModal] = useState<boolean>(false);
    const [selectedFeedCommentId, setSelectedFeedCommentId] = useState<number>(0);
    const [isOpenMyCommentModal, setIsOpenMyCommentModal] = useState<boolean>(false);
    const [commentInput, setCommentInput] = useState<string>("");
    const createFeedLikeWeb = useCreateFeedLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
        }
    })

    const deleteFeedLikeWeb = useDeleteFeedLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
        }
    })

    const createFeedCommentWeb = useCreateFeedCommentWeb((res: any) => {
        if (res.data) {
            message.success("댓글이 생성되었습니다.")
            setCommentInput('')
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
        }
    })

    const createFeedCommentLikeWeb = useCreateFeedCommentLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
        }
    })

    const deleteFeedCommentLikeWeb = useDeleteFeedCommentLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedWebByFeedId'] })
        }
    })

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError || !feedDetail) {
        return <div>Error loading feed.</div>;
    }

    return <>
        <TopBar title="게시글" rightIcon={false} thema='dark' />
        <div style={{ padding: 20 }}>
            <Flex justify="space-between">
                <Flex style={{ alignItems: 'center' }} gap={8}>
                    <div className="sub-title/st2">{feedDetail.nickName}</div>
                    <div className="caption/c1 secondary/s500">{dayjs(feedDetail.createdDate).format('YY.MM.DD')}</div>
                </Flex>
                <div>
                    <Button type="text" icon={<More />} onClick={() => {
                        // if(feedDetail.memberId == feedDetail.requestMemberId) {

                        // }
                        setIsOpenFeedClaimModal(true)
                    }}></Button>
                </div>
            </Flex>
            <div>
                <div className="body/b3" style={{ marginTop: 8 }}>{feedDetail.content}</div>
                <div style={{ marginTop: 8 }}>
                    <Flex style={{ overflow: 'scroll' }} gap={12}>
                        {feedDetail.imageUrlList.map((imageUrl: string) => {
                            return <div style={{}}><img src={imageUrl} style={{ maxHeight: 200, borderRadius: 4 }} /></div>
                        })}
                    </Flex>
                </div>
            </div>
            {feedDetail.feedLikeList.some((feedLike: IFeedLikeWebResponseGraphQl) => feedLike.memberId === feedDetail.requestMemberId)
                ? (
                    <Button
                        type="text"
                        icon={<FillOn />}
                        style={{ color: 'var(--errore400)' }}
                        onClick={() => deleteFeedLikeWeb.mutate(feedDetail.id)}
                    >
                        {feedDetail.feedLikeList.length}
                    </Button>
                ) : (
                    <Button
                        type="text"
                        icon={<FillOff />}
                        onClick={() => createFeedLikeWeb.mutate(feedDetail.id)}
                    >
                        {feedDetail.feedLikeList.length}
                    </Button>
                )}
            <Button type="text" icon={<Bubble />}>{feedDetail.feedCommentList.length}</Button>
        </div>

        <Divider style={{ borderBlockStart: '6px solid var(--secondarys100)', margin: '0' }} />
        <div className="sub-title/st3" style={{ margin: '20px 20px 0 20px' }}>댓글 ({feedDetail.feedCommentList.length})</div>

        {feedDetail.feedCommentList.map((feedComment: IFeedCommentWebResponseGraphQl) => {
            return (
                <div style={{ padding: 20 }}>
                    <Flex justify="space-between">
                        <Flex style={{ alignItems: 'center' }} gap={8}>
                            <div className="sub-title/st2">{feedComment.nickName.nickName}</div>
                            <div className="caption/c1 secondary/s500">{dayjs(feedComment.createdDate).format('YY.MM.DD')}</div>
                        </Flex>
                        <div>
                            <Button type="text" icon={<More />} onClick={() => {
                                setSelectedFeedCommentId(feedComment.id)
                                feedComment.memberId == feedDetail.requestMemberId ? setIsOpenMyCommentModal(true) : setIsOpenFeedClaimModal(true)
                            }}></Button>
                        </div>
                    </Flex>
                    <div>
                        <div className="body/b3" style={{ marginTop: 8 }}>{feedComment.content}</div>
                    </div>
                    {feedComment.feedCommentLikeList.some((feedCommentLike: IFeedCommentLikeWebResponseGraphQl) => feedCommentLike.memberId === feedDetail.requestMemberId)
                        ? (
                            <Button
                                type="text"
                                icon={<FillOn />}
                                style={{ color: 'var(--errore400)', padding: 0, marginTop: 12 }}
                                onClick={() => deleteFeedCommentLikeWeb.mutate(feedComment.id)}
                            >
                                {feedComment.feedCommentLikeList.length}
                            </Button>
                        ) : (
                            <Button
                                type="text"
                                icon={<FillOff />}
                                onClick={() => createFeedCommentLikeWeb.mutate(feedComment.id)}
                                style={{ padding: 0, marginTop: 12 }}
                            >
                                {feedComment.feedCommentLikeList.length}
                            </Button>
                        )}
                </div>
            )
        })}
        <div style={{ height: 100 }}></div>
        <div style={{ position: 'fixed', bottom: 0, borderTop: '1px solid var(--secondarys100)', width: '100%', maxWidth: '480px', backgroundColor: '#ffffff' }}>
            <div style={{ padding: '16px 20px' }}>
                <Flex gap={12}>
                    <TextArea
                        variant="borderless"
                        autoSize={{ minRows: 2, maxRows: 2 }}
                        placeholder={`${feedDetail.nickName}님께 댓글 달기`}
                        maxLength={3000}
                        style={{ padding: 0, }}
                        onChange={(e) => setCommentInput(e.target.value)}
                        value={commentInput}
                    />
                    {commentInput !== '' && <>
                        <Button color="default" variant="solid" onClick={() => {
                            createFeedCommentWeb.mutate({
                                feedId: id,
                                content: commentInput
                            })
                        }} > 게시</Button>
                    </>}
                </Flex>
            </div>
        </div >

        {isOpenFeedClaimModal && <FeedClaimBottomSheet
            feedId={id}
            isOpen={isOpenFeedClaimModal}
            setIsOpen={setIsOpenFeedClaimModal}
        />}

        {isOpenMyCommentModal && <MyCommentBottomSheet
            feedCommentId={selectedFeedCommentId}
            isOpen={isOpenMyCommentModal}
            setIsOpen={setIsOpenMyCommentModal}
        />}

    </>
}

export default CommunityFeedDetail