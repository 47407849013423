import { CustomTabs } from "../../../shared"
import { TopBar } from "../../../widgets"
import MyReservationSkeleton from "../../../components/skeleton/my/myReservationSkeleton";
import { useGetMyReservationHistory } from "./model";
import './index.css'
import PendingReservation from "./ui/pendingReservation";
import ConfirmReservation from "./ui/confirmedReservation";
import CompleteReservation from "./ui/completeReservation";
import CancelReservation from "./ui/canceledReservation";

const ReservationHistory = () => {
    const { data: reservationList, isLoading } = useGetMyReservationHistory();

    return <>
        {isLoading && <MyReservationSkeleton />}
        <TopBar title="예약 내역" rightIcon={false} />
        <CustomTabs items={[
            {
                key: 0,
                label: '예약 대기',
                children: <>
                    <div style={{ padding: 20 }}>
                        {reservationList && <PendingReservation reservationList={reservationList.filter((reservation: IGetMyReservationHistoryResponse) => reservation.reservationType === '예약 대기')} />}
                    </div>
                </>
            },
            {
                key: 1,
                label: '예약 확정',
                children: <>
                    <div style={{ padding: 20 }}>
                        {reservationList && <ConfirmReservation reservationList={reservationList.filter((reservation: IGetMyReservationHistoryResponse) => reservation.reservationType === '예약 확정')} />}
                    </div>
                </>
            },
            {
                key: 2,
                label: '이용 완료',
                children: <>
                    <div style={{ padding: 20 }}>
                        {reservationList && <CompleteReservation reservationList={reservationList.filter((reservation: IGetMyReservationHistoryResponse) => reservation.reservationType === '이용 완료')} />}
                    </div>
                </>
            },
            {
                key: 3,
                label: '예약 취소',
                children: <>
                    <div style={{ padding: 20 }}>
                        {reservationList && <CancelReservation reservationList={reservationList.filter((reservation: IGetMyReservationHistoryResponse) => reservation.reservationType === '예약 취소')} />}
                    </div>
                </>
            },
        ]}
            defaultkey={0} />
    </>
}

export default ReservationHistory