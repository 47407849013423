import { Navigate, useNavigate, useParams } from "react-router-dom";
import { TopBar } from "../../../../widgets";
import { DividerThin } from "../../../../shared";
import { useEffect, useState } from "react";
import { Button, Flex, InputNumber } from "antd";
import './index.css'
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { getProgramBookingAsync } from "../../../../modules/programBooking";
import { RootState } from "../../../../modules";
import { useGetProgramById } from "./model";
import WellnessProgramBookCalendar from "./ui/wellnessProgramBookCalendar";
import dayjs from "dayjs";
import { ConfirmBottomSheet } from "../secondStep/confirmBottomSheet";


const WellnessProgramBookFirstStep = () => {
    const id = Number(useParams().id);
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const update = getProgramBookingAsync;
    const { form, ticketList } = useSelector((state: RootState) => state.programBooking);
    const [isShowConfirmButtomSheet, setIsShowConfirmButtomSheet] = useState<boolean>(false);
    const navigate = useNavigate();
    const { data, isFetched } = useGetProgramById(id, 'id, title, thumbnailImageUrl, locationName, isPaid programTicketList { id, name, description, payAmount } programEventList { id targetDateTime } programSelectOptionList { id idx name description isRequired } programSubjectOptionList { id idx name description isRequired }')

    useEffect(() => {
        if (!data) return;

        const targetWellnessProgram = {
            ...form,
            'id': data.id,
            'title': data.title,
            'imageUrl': data.thumbnailImageUrl,
            'locationName': data.locationName,
            'date': dayjs(data.programEventList[0].targetDateTime).format('YYYY-MM-DD')
        };

        const targetTicketList = data.isPaid ? data.programTicketList?.map((programTicekt: IProgramTicket) => ({
            id: programTicekt.id,
            name: programTicekt.name,
            description: programTicekt.description,
            payAmount: programTicekt.payAmount,
            count: 0
        })) : [{
            id: 0,
            name: 'free',
            description: '무료',
            payAmount: 0,
            count: 0
        }];

        dispatch(update({
            form: targetWellnessProgram,
            ticketList: targetTicketList
        }))
    }, [data])


    const increase = (key: string) => {
        dispatch(update({
            form: form,
            ticketList: ticketList.map(ticket => ticket.name === key ? { ...ticket, count: ticket.count + 1 } : ticket)
        }))
    };

    const decrease = (key: string) => {
        dispatch(update({
            form: form,
            ticketList: ticketList.map(ticket => ticket.name === key ? { ...ticket, count: ticket.count - 1 } : ticket)
        }))
    };

    const getTotalBookingCnt = () => {
        return ticketList.reduce((sum, ticket) => sum + ticket.count, 0);
    }

    const updateForm = (key: string, value: number | string) => {
        const updatedCount = { ...form, [key]: value };
        dispatch(update({
            form: updatedCount,
            ticketList: ticketList
        }))
    }

    const onClickNextStepButton = () => {
        if (!data) return;
        if (data.programSelectOptionList.length + data.programSubjectOptionList.length > 0) {
            navigate(`/wellness-program/book/second-step/${id}`)
        } else {
            setIsShowConfirmButtomSheet(true)
        }
    }

    const getBookingData = (): IBookingData => {
        const returnValue = {
            programId: id,
            title: form[`title`].toString(),
            imageUrl: form[`imageUrl`].toString(),
            locationName: form[`locationName`].toString(),
            date: form[`date`].toString(),
            startTime: form[`startTime`].toString(),
            totalBookingCtn: getTotalBookingCnt(),
            totalPrice: Number(form['totalPrice']),
            eventId: Number(form['eventId']),
            optionList: {
                selectOptionList: [],
                subjectOptionList: []
            },
            ticketList: ticketList
        }
        return returnValue
    }

    if (isNaN(id)) return <Navigate to={'/explore'} />

    return <>
        <TopBar title="예약" />
        {data?.isPaid
            ? <div style={{}}>
                {data?.programTicketList?.map(programTicket =>
                    <div key={programTicket.id} style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', padding: '20px', userSelect: 'none', gap: 16 }}>
                        <div>
                            <div className="sub-title/st3 secondary/s850">
                                {programTicket.name}
                            </div>
                            <div className="sub-title/st2 secondary/s600">
                                {programTicket.payAmount}원
                            </div>
                            <div className="body/b2 secondary/s600">
                                {programTicket.description}
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomButton disabled={(ticketList.find(ticket => ticket.name === programTicket.name)?.count ?? 0) === 0} style={{ fontSize: 48, fontWeight: 100, color: (ticketList.find(ticket => ticket.name === programTicket.name)?.count ?? 0) === 0 ? 'var(--secondarys300)' : 'var(--secondarys850)' }} onClick={() => (ticketList.find(ticket => ticket.name === programTicket.name)?.count ?? 0) !== 0 && decrease(programTicket.name)}>-</CustomButton>
                            <InputNumber
                                className="centered-input"
                                bordered={false}
                                value={ticketList.find(ticket => ticket.name === programTicket.name)?.count ?? 0}
                                min={0}
                                onChange={q => updateForm(programTicket.name, q ?? 0)}
                                controls={false}
                                style={{ boxShadow: 'none', width: 50 }}
                            />
                            <CustomButton style={{ fontSize: 36, fontWeight: 100, color: 'var(--secondarys850)' }} onClick={() => increase(programTicket.name)}>+</CustomButton>
                        </div>
                    </div>)}
            </div>
            : <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', padding: '20px', gap: 16 }}>
                <div className="sub-title/st3">
                    인원
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CustomButton disabled={(ticketList.find(ticket => ticket.name === 'free')?.count ?? 0) === 0} style={{ fontSize: 48, fontWeight: 100, color: (ticketList.find(ticket => ticket.name === 'free')?.count ?? 0) === 0 ? 'var(--secondarys300)' : 'var(--secondarys850)' }} onClick={() => (ticketList.find(ticket => ticket.name === 'free')?.count ?? 0) !== 0 && decrease('free')}>-</CustomButton>
                    <InputNumber
                        className="centered-input"
                        bordered={false}
                        value={ticketList.find(ticket => ticket.name === 'free')?.count ?? 0}
                        min={0}
                        onChange={q => updateForm('free', q ?? 0)}
                        controls={false}
                        style={{ boxShadow: 'none', width: 50 }}
                    />
                    <CustomButton style={{ fontSize: 36, fontWeight: 100, color: 'var(--secondarys850)' }} onClick={() => increase('free')}>+</CustomButton>
                </div>
            </div>}
        <div style={{ padding: '20px' }}>
            <DividerThin />
        </div>
        <div style={{ paddingInline: '20px' }}>
            {data && <WellnessProgramBookCalendar
                initDate={dayjs(data.programEventList[0].targetDateTime).format('YYYY-MM-DD')}
                onChange={(date) => {
                    const { eventId, startTime, ...updatedForm } = form;
                    const updatedCount = { ...updatedForm, date: date };
                    dispatch(update({
                        form: updatedCount,
                        ticketList: ticketList
                    }))
                }}
                onActiveDateList={data.programEventList.map((event) => dayjs(event.targetDateTime).format("YYYY-MM-DD"))} />}

        </div>
        <div style={{ padding: '20px' }}>
            <DividerThin />
        </div>
        <div style={{ padding: '20px' }}>
            <div className="sub-title/st3" style={{ marginBottom: '12px' }}>
                시간
            </div>
            <Flex style={{ marginTop: 20 }}>
                {data?.programEventList
                    .filter((event) => dayjs(event.targetDateTime).format('YYYY-MM-DD') === form.date)
                    .map((event) =>
                        <Button
                            key={event.id}
                            onClick={() => {
                                const updatedCount = {
                                    ...form,
                                    'eventId': event.id,
                                    'startTime': dayjs(event.targetDateTime).format('HH:mm')
                                };
                                dispatch(update({
                                    form: updatedCount,
                                    ticketList: ticketList
                                }))
                            }}
                            color="default"
                            variant={form["eventId"] === event.id ? "solid" : "outlined"}
                            className="sub-title/st2"
                            style={{ padding: '8px 12px', marginRight: '12px' }}>{dayjs(event.targetDateTime).format('HH:mm')}</Button>
                    )}
            </Flex>
        </div>
        <div style={{ padding: '20px' }}>
            <Button
                color="default"
                variant="solid"
                disabled={getTotalBookingCnt() <= 0 || !form['eventId']}
                className="sub-title/st3 base/white"
                style={{ width: '100%', height: '51px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                onClick={onClickNextStepButton}>
                다음
            </Button>
        </div>

        {isShowConfirmButtomSheet &&
            <ConfirmBottomSheet
                isOpen={isShowConfirmButtomSheet}
                setIsOpen={setIsShowConfirmButtomSheet}
                bookingData={getBookingData()}
                contentHeight={500}
            />}
    </>
}

const CustomButton = ({ style, disabled, ...props }: React.ButtonHTMLAttributes<HTMLDivElement>) => {
    return <div
        style={{ borderRadius: '50%', height: '32px', lineHeight: '32px', cursor: disabled ? 'default' : 'pointer', padding: '4px 8px', ...style }}
        {...props}
    />
}
export { WellnessProgramBookFirstStep }