import { Flex } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../../../shared/reservationBadge";


interface IProps {
    dataList: Array<IWellnessLectureDetail> | null
}

const LectureCardInTeacherDetail = ({ dataList }: IProps) => {
    const navigate = useNavigate();
    const getReservationBadge = (yogaLecture: IWellnessLectureDetail) => {

        if (yogaLecture.isReservation) {
            return <ReservationBadge status="Complete" />
        }

        if (dayjs().isAfter(dayjs(yogaLecture.startDateTime))) {
            return <ReservationBadge status="Close" />
        }

        if (yogaLecture.maxReservationCnt - yogaLecture.nowReservationCnt > 0) {
            return <ReservationBadge status="Available" />
        }

        return <ReservationBadge status="Close" />
    }

    return <>
        {dataList === null
            ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
            : dataList.length === 0
                ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
                : <div style={{ paddingTop: 8 }}>
                    {dataList.map((yogaLecture, idx) => <div
                        key={idx}
                        style={{ marginTop: 16, padding: '9.5px 0', cursor: 'pointer' }}
                        onClick={() => navigate(`/lecture/${yogaLecture.id}`)}
                    >
                        <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                            <div style={{ width: '20%' }}>
                                <img src={yogaLecture.lectureImageUrl} alt="수업사진" width={'100%'} height={'100%'} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                            </div>
                            <div style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <div>
                                            <div className="body/b1 secondary/s800">{dayjs(yogaLecture.startDateTime).format('YYYY.MM.DD (dd)')}</div>
                                        </div>
                                        {yogaLecture.yogaProgramIsShow &&
                                            <div style={{ color: 'var(--secondarys800)', fontSize: '12px' }}>
                                                | <span style={{ fontWeight: 600, lineHeight: '14.32px' }}>{yogaLecture.yogaProgramName}</span> |
                                            </div>}
                                    </div>
                                    <div>
                                        {getReservationBadge(yogaLecture)}
                                    </div>
                                </div>
                                <div className="sub-title/st3">
                                    {yogaLecture.yogaLectureName}
                                </div>
                                <div className="body/b1 secondary/s500">
                                    {dayjs(yogaLecture.startDateTime).format('HH:mm')}-{dayjs(yogaLecture.endDateTime).format('HH:mm')} · {yogaLecture.yogaCenterName} {yogaLecture.room}
                                </div>
                            </div>
                        </Flex>
                    </div>
                    )}
                </div>}
    </>
}

export { LectureCardInTeacherDetail }