import { useMutation, useQuery } from "@tanstack/react-query"
import { cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest, getProgramBookingWebByProgramBookingId } from "../../../../../../service/program";

const useGetProgramBookingWebByProgramBookingId = (id: number, responseQuery?: string) => {
    const query = useQuery({
        queryKey: ['getProgramById', id, responseQuery],
        queryFn: () => getProgramBookingWebByProgramBookingId(id, responseQuery),
        enabled: !!id,
        select: res => res.data.data.getProgramBookingWebByProgramBookingId,
    });
    return query;
}

const useCancelProgramBookingStatusWebByCancelProgramBookingStatusRequest = () => {
    const mutation = useMutation({
        mutationFn: cancelProgramBookingStatusWebByCancelProgramBookingStatusRequest,
    });

    return mutation;
}

export { useGetProgramBookingWebByProgramBookingId, useCancelProgramBookingStatusWebByCancelProgramBookingStatusRequest };