import api from './Api'

export const myReservationByToken = () => {
    return api.get<IMyReservation[]>(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation/token`);
}
export const cancelReservation = (lectureId: number) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/mobile/reservation/${lectureId}`)
}
export const updateReservationOnCheckIn = (reservation: IUpdateReservationForCheckIn) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/reservation/wellness-lecture/update/web/v2`, reservation)
}
export const getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse = (yogaCenterId: number, startDateTime: string, endDateTime: string) => {
    return api.get<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse[]>(`${process.env.REACT_APP_API_DOMAIN}/reservation/yoga-center/wellness-lecture/web/v2?yogaCenterId=${yogaCenterId}&startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${encodeURIComponent(endDateTime)}`);
}
export const getAllReservationByMemberIdAndReservationStatus = (reservationStatus?: string) => {
    return api.get<IReservationMyWebResponseV2[]>(`${process.env.REACT_APP_API_DOMAIN}/reservation/my/web/v2?reservationStatus=${reservationStatus}`);
}
export const createOrUpdateReservationByMemberIdAndIssuedWellnessTicketIdAndWellnessLectureIdAndReservationStatus = (request: IReservationByMemberIdAndIssuedWellnessTicketIdAndWellnessLectureIdAndReservationStatusRequestV2) => {
    return api.post<string>(`${process.env.REACT_APP_API_DOMAIN}/reservation/wellness-lecture/web/v2`, request);
}
export const cancelReservationByMemberIdAndReservationId = (reservationId: number) => {
    return api.put<string>(`${process.env.REACT_APP_API_DOMAIN}/reservation/wellness-lecture/cancel/web/v2/${reservationId}`);
}

export const getMyReservedDateList = (yogaCenterId: number) => {
    return api.get<string[]>(`${process.env.REACT_APP_API_DOMAIN}/mobile/my/reserved/date/v2?yogaCenterId=${yogaCenterId}`);
}