import { useNavigate } from "react-router-dom";
import { Button, Flex } from "antd";
import { DividerThin } from "../../../../../shared";

interface IProps {
    date: string
    totalBookingCnt: number
    onClickBookingBtn: Function
}

const BookingSecondStepFooter = ({ date, totalBookingCnt, onClickBookingBtn }: IProps) => {
    const navigate = useNavigate();

    return <>
        <div style={{ height: '72px' }}></div>
        {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px' }}></div>}
        {/* footer start */}
        <div style={{ position: "fixed", bottom: "0", width: "100%", maxWidth: "480px" }}>
            <DividerThin style={{ height: '1px' }} />
            <Flex gap={16} style={{ padding: 20 }}>
                <div style={{ flex: 1 }}>
                    <div className="body/b2">{date}</div>
                    <div className="body/b2">{totalBookingCnt}명</div>
                </div>
                <div style={{ flex: 1 }}>
                    <Button onClick={() => onClickBookingBtn()} variant="solid" color="default" style={{ width: '100%', height: 51 }}>
                        <div className="sub-title/st3">
                            신청하기
                        </div>
                    </Button>
                </div>
            </Flex>
            {(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.native_yoga_bridge) && <div style={{ height: '24px', backgroundColor: 'black', width: '100%' }}></div>}
        </div >
        {/* footer end */}
    </>
}

export default BookingSecondStepFooter;