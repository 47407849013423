import { useEffect, useState } from "react";
import ReservationSkeleton from "../../components/skeleton/reservation/reservationSkeleton";
import { YogaCenterSelcetorModal } from "./ui/yogaCenterSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowRight } from "../../assets/images/icon/arrow-right.svg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import { ThunkDispatch } from "redux-thunk";
import { LectureCardInReservation, GnbFooter } from "../../shared";
import { isLogin } from "../../utils";
import { getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse, getMyReservedDateList, getUsedYogaCenterIdByMobile } from "../../service";
import { useMyContext } from "../../entities/context";
import WellnessLectureSkeleton from "../../components/skeleton/reservation/wellnessLectureSkeleton";
import { getSelectedYogaCenteAndDaterAsync } from "../../modules/selectedYogaCenterAndDate";
import CalendarReservation from "./ui/calendarReservation";
import { useQuery } from '@tanstack/react-query';

const Reservation = () => {
    const location = useLocation();
    const update = getSelectedYogaCenteAndDaterAsync;
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const navigate = useNavigate();
    const { yogaCenterId, date } = useSelector((state: RootState) => state.selectedYogaCenterAndDate);
    const paramId = location.search && new URLSearchParams(location.search).get("id");
    const { topHeight } = useMyContext();
    const [initLoading, setInitLoading] = useState<boolean>(true);

    useEffect(() => {
        if (paramId) {
            dispatch(update({ yogaCenterId: Number(paramId), date: date }));
        } else if (isLogin()) {
            getUsedYogaCenterIdByMobile()
                .then((res) => {
                    let reduxYogaCenterId = yogaCenterId || res.data.selectYogaCenter.id || 3;
                    dispatch(update({ yogaCenterId: reduxYogaCenterId, date: date }));
                })
                .catch(error => console.error("Error: ", error));
        }
    }, [paramId, dispatch, yogaCenterId, update]);

    const { data: wellnessLectures, isLoading: isFetchingLectures, isSuccess } = useQuery({
        queryKey: ['wellnessLectures', yogaCenterId, date],
        queryFn: () => {
            return getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse(
                yogaCenterId,
                dayjs(date).format('YYYY-MM-DDT00:00:00Z'),
                dayjs(date).format('YYYY-MM-DDT23:59:59Z')
            )
        },
        select: (res) => res.data,
        enabled: !!yogaCenterId && !!date,
    });

    useEffect(() => {
        if (isSuccess) {
            setInitLoading(false);
        }
    }, [isSuccess]);

    const { data: reservedDateList } = useQuery({
        queryKey: ['reservedDates', yogaCenterId],
        queryFn: () => getMyReservedDateList(yogaCenterId),
        select: res => res.data.map((e: string) => dayjs(e).format('YYYY-MM-DD')),
        enabled: !!yogaCenterId && isLogin()
    });


    return (
        <>
            {initLoading && <ReservationSkeleton />}
            <div style={{ paddingTop: topHeight + 'px' }}></div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'baseline', padding: '13px 20px' }} >
                {!initLoading && <YogaCenterSelcetorModal selectedYogaCenterId={yogaCenterId} setSelectedYogaCenterId={(selectedYogaCenterId: number) => dispatch(update({ yogaCenterId: selectedYogaCenterId, date: date }))} />}
                <div onClick={() => navigate(`/yoga-center/${yogaCenterId}`)}>
                    <div style={{ display: "flex", gap: "4px", cursor: "pointer", alignItems: 'center' }}>
                        <div className="btn/text-btn secondary/s600">스튜디오 소개</div>
                        <ArrowRight width={12} height={12} stroke={"#737373"} />
                    </div>
                </div>
            </div>

            <CalendarReservation reservedDateList={reservedDateList} />

            {!isFetchingLectures && wellnessLectures ? (
                <LectureCardInReservation dataList={wellnessLectures} />
            ) : (
                <WellnessLectureSkeleton />
            )}

            <div style={{ height: '100px' }}></div>
            <GnbFooter activeButton='reservation' />
        </>
    );
};

export { Reservation };