import { Flex } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextBtn } from "../../shared";
import dayjs from "dayjs";
import { Carousel as CarouselAntd } from "antd"

interface IProps {
    dosanProgramMember: IDosanProgramMember | undefined;
    requestMyReservationByToken: Function;
    requestMyTicketByToken: Function;
    myReservationList: IReservationMyWebResponseV2[];
}

const ReservatedProgramList = ({ dosanProgramMember, requestMyReservationByToken, requestMyTicketByToken, myReservationList }: IProps) => {
    const navigate = useNavigate();
    const [isRequestCancel, setIsRequestCancel] = useState<boolean>(false);
    return <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space12)' }}>
        <div className="title/t1" style={{ paddingBlock: 'var(--space8)', paddingInline: 'var(--space20)' }}>다가오는 프로그램</div>
        {dosanProgramMember &&
            <>
                <div style={{ backgroundColor: '#000000', fontSize: '13px', color: '#ffffff', display: 'flex', alignItems: 'center', marginTop: '28px', borderRadius: "4px", padding: '12px 16px', gap: '16px' }} onClick={() => navigate('/invitation/dosan/program')}>
                    <div><img alt="" src={`${process.env.PUBLIC_URL}/assets/icon/ClubhouseProgram.svg`} /></div>
                    <div>
                        <div style={{ color: '#F4F4F4', fontSize: '12px', lineHeight: '20px' }}>{dosanProgramMember?.startDate}</div>
                        <div style={{ marginTop: '4px', color: '#ffffff', fontSize: '16px', lineHeight: '23px', fontFamily: 'TuskerGrotesk6500Medium' }}>GOOD IN BAD OUT</div>
                    </div>
                </div>
            </>}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space12)' }}>
            {myReservationList.length === 0
                ? <div style={{ paddingInline: 20 }}>
                    <div style={{ padding: 16, backgroundColor: 'var(--secondarys100)', borderRadius: 'var(--radius4)' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%', textAlign: 'center' }}>
                            <div className="body/b2 secondary/s800">
                                아직 다가오는 프로그램이 없습니다
                            </div>
                            <TextBtn type='underline' textDecorationColor='' style={{ color: 'var(--secondarys800)' }} onClick={() => navigate('/reservation')} icon={false}>
                                프로그램 둘러보기
                            </TextBtn>
                        </div>
                    </div>
                </div>
                : <div style={{ marginBottom: '40px', paddingInline: myReservationList.length === 1 ? "20px" : "20px 0" }}>
                    <CarouselAntd draggable autoplay autoplaySpeed={5000} style={{ marginBottom: '-15px' }} slidesToShow={myReservationList.length === 1 ? 1 : 1.024} dots={true} dotPosition="bottom">
                        {myReservationList.map((myReservation, idx) => <>
                            <div
                                key={idx}
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/lecture/${myReservation.wellnessLectureId}`)}
                            >
                                <Flex style={{ backgroundColor: 'var(--secondarys100)', borderRadius: '4px', justifyContent: 'space-between', padding: '8px 12px', marginLeft: myReservationList.length === 1 ? 0 : 12 }} gap={16}>
                                    <div style={{ paddingBlock: '6.5px' }}>
                                        <img src={myReservation.teacherProfileImageUrl} alt="수업사진" width={56} height={56} style={{ borderRadius: "999px", aspectRatio: 1, objectFit: 'cover' }} />
                                    </div>
                                    <div style={{ width: '80%', display: 'flex', flexDirection: 'column', gap: '4px', justifyContent: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            {/* <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                                <div>
                                                    <div className="body/b1 secondary/s800">{dayjs(myReservation.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')}</div>
                                                </div>
                                            </div>
                                            <div>
                                                <TextBtn type='underline' icon={false} onClick={e => e.stopPropagation()} styles={{ container: { padding: '4px' } }}>
                                                    {isRequestCancel
                                                        ? <Spin indicator={<LoadingOutlined spin />} />
                                                        : <CancelReservationWithModal
                                                            text={"취소"}
                                                            myReservation={myReservation}
                                                            isRequestCancel={isRequestCancel}
                                                            setIsRequestCancel={setIsRequestCancel}
                                                            requestMyReservationByToken={requestMyReservationByToken}
                                                            requestMyTicketByToken={requestMyTicketByToken}
                                                        />}
                                                </TextBtn>
                                            </div> */}
                                        </div>
                                        <div className="sub-title/st3">
                                            {myReservation.wellnessLectureName}
                                        </div>
                                        <div className="body/b1 secondary/s500" style={{ marginTop: 8 }}>
                                            {dayjs(myReservation.wellnessLectureStartDateTime).format('YYYY.MM.DD (dd) HH:mm')} | {myReservation.yogaCenterName} {myReservation.wellnessLectureRoom}
                                        </div>
                                    </div>
                                </Flex>
                            </div>
                        </>)}
                    </CarouselAntd>
                </div>
            }
        </div>
    </div>
}
export { ReservatedProgramList };