import { Fragment, useEffect, useState } from "react";
import { getNoticePopup, removeUser } from "../../../service";
import NotificationSkeleton from "../../../components/skeleton/notification/notificationSkeleton";
import { TopBar } from "../../../widgets";
import { Link } from "react-router-dom";
import { DividerThin } from "../../../shared";
import dayjs from "dayjs";

const Notice = () => {
    const [noticePopupList, setNoticePopupList] = useState<Array<INoticePopup>>([]);
    const [isInitLoading, setIsIntLoading] = useState<boolean>(true);

    useEffect(() => {
        requestNoticePopup().finally(() => { setIsIntLoading(false) });
    }, [])

    const requestNoticePopup = async () => {
        await getNoticePopup()
            .then((res) => { setNoticePopupList(res.data.filter((i) => !i.isDelete).sort((a, b) => dayjs(b.createdDate).diff(dayjs(a.createdDate)))); })
            .catch((error) => {
                removeUser();
                console.error("Error: ", error);
            })
    }

    return <>
        {isInitLoading && <NotificationSkeleton />}
        <TopBar rightIcon={false} title="공지사항" />
        <div style={{ padding: 20 }}>
            {noticePopupList.length <= 0
                ? <div className="body/b2 secondary/s500" style={{ marginBlock: '50%', textAlign: "center" }}>등록된 공지사항이 없습니다</div >
                : noticePopupList.map((noticePopup, idx) => <Fragment key={noticePopup.id}>
                    <Link to={`/notice-popup/${noticePopup.id}`} style={{ display: 'block', textDecorationLine: 'none', paddingBlock: 20 }} key={noticePopup.id} >
                        <div className="sub-title/st2 base/black" >{noticePopup.title}</div>
                        <div className="body/b1 secondary/s500" style={{ marginTop: '4px' }}>{dayjs(noticePopup.createdDate).format("YYYY년 MM월 DD일")}</div>
                    </Link>
                    {noticePopupList.length - 1 !== idx && <DividerThin style={{ marginBlock: 'var(--space4)' }} />}
                </Fragment>)
            }
        </div>
    </>
}

export default Notice