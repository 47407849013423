import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTeacherAndLectureList } from "../../../service";
import dayjs from "dayjs";
import 'dayjs/locale/ko';
import { ReactComponent as ArrowDown } from '../../../assets/images/icon/arrow-down.svg';
import { ReactComponent as ArrowUp } from '../../../assets/images/icon/arrow-up.svg';
import TeacherDetailSkeleton from "../../../components/skeleton/teacher/teacherDetailSkeleton";
import { LectureCardInTeacherDetail } from "./ui/lectureCardInTeacherDetail";
import { TopBar } from "../../../widgets";
dayjs.locale('ko');

const TeacherDetail = () => {
    const [teacherData, setTeacherData] = useState<ITeacherDetail>();
    const [careerStatus, setCareerStatus] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isInitLoading, setIsInitLoading] = useState<boolean>(true);
    const { id } = useParams();

    useEffect(() => {
        if (!id) return;
        getTeacherAndLectureList(id)
            .then((res) => {
                setTeacherData(res.data)
                const img = new Image();
                img.src = res.data.profileImageUrl;
                img.onload = () => {
                    setImageLoaded(true);
                }
            })
            .catch((error) => {
                console.error("error: ", error);
                setIsInitLoading(false);
            })
    }, [id])

    useEffect(() => { if (imageLoaded) setIsInitLoading(false) }, [imageLoaded])

    return <>
        {isInitLoading ? <TeacherDetailSkeleton /> : <>
            <div style={{ padding: "0 0 30px 0" }}>
                <TopBar title="코치 소개" rightIcon={false} />
                {teacherData && <>
                    <div>
                        <img src={teacherData.profileImageUrl} alt="" style={{ width: "100%" }} />
                    </div>
                    <div style={{ margin: "20px", display: "flex", flexDirection: "column", gap: "24px" }}>
                        <div style={{ display: "flex", flexDirection: "column", gap: "14px", paddingBottom: "20px", borderBottom: "1px solid #EBEBEB" }}>
                            <div style={{ fontSize: "18px", fontWeight: "700" }}>
                                {teacherData.name} 코치
                            </div>
                            <div style={{ whiteSpace: "pre-wrap", fontSize: "13px", color: "#303030" }}>
                                {teacherData.introduce}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", gap: careerStatus ? "15px" : "", paddingBottom: "20px", borderBottom: "1px solid #EBEBEB" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", cursor: "pointer" }} onClick={() => setCareerStatus((curr) => !curr)}>
                                <div style={{ fontSize: "18px", fontWeight: "700" }}>
                                    주요 이력
                                </div>
                                <div>
                                    {careerStatus ? <ArrowUp width={16} height={16} stroke="#555555" /> : <ArrowDown width={16} height={16} stroke="#555555" />}
                                </div>
                            </div>
                            <div style={{ fontSize: "13px", color: "#777777", whiteSpace: "pre-wrap" }}>
                                {careerStatus && teacherData.career}
                            </div>
                        </div>

                        {teacherData.wellnessLectureList.length > 0 && <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "18px", fontWeight: "700" }}>
                                다가오는 수업
                            </div>
                            {teacherData.wellnessLectureList && <>
                                <LectureCardInTeacherDetail dataList={teacherData.wellnessLectureList} />
                            </>}
                        </div>
                        }
                    </div>
                </>}
            </div>
        </>}
    </>
}
export default TeacherDetail;