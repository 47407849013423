import { Button, Flex, Modal, message } from "antd";
import "./index.css"
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useCreateProgramBookingByMemberIdAndCreateProgramBookingWebRequest } from "../model";

interface IProps {
    isOpen: boolean
    setIsOpen: Function
    bookingData: IBookingData
    contentHeight?: number
}

const ConfirmBottomSheet = ({ isOpen, setIsOpen, bookingData, contentHeight = 600 }: IProps) => {
    const minWidth = window.innerWidth <= 480 ? '100vw' : '480px';
    const marginInline = window.innerWidth <= 480 ? '0' : (window.innerWidth - 480) / 2;
    const navigate = useNavigate();
    const createProgramBookingByMemberIdAndCreateProgramBookingWebRequest = useCreateProgramBookingByMemberIdAndCreateProgramBookingWebRequest()

    const formatDate = (dateString: string): string => {
        return dayjs(dateString).format("YYYY.MM.DD (ddd)");
    };

    const onClickCreateButton = () => {
        const createParams: CreateProgramBookingWebRequest = {
            programId: bookingData.programId,
            programBookingTicketList: bookingData.ticketList.map((ticket: IProgramBookingTicket) => {
                return {
                    programTicketId: Number(ticket.id),
                    count: ticket.count
                }
            }),
            programEventId: bookingData.eventId,
            programBookingSelectOptionList: bookingData.optionList.selectOptionList.map((selectOption: ISelectOption) => ({
                programSelectOptionId: Number(selectOption.id),
                programSelectOptionItemIdList: [Number(selectOption.selectedOptionId)]
            })),
            programBookingSubjectOptionList: bookingData.optionList.subjectOptionList.map((subjectOption: ISubjectOption) => ({
                programSubjectOptionId: Number(subjectOption.id),
                content: subjectOption.value
            }))
        }

        createProgramBookingByMemberIdAndCreateProgramBookingWebRequest.mutate(createParams, {
            onSuccess: res => {
                if (!res.data.errors) {
                    message.success('성공했습니다.')
                    navigate('/wellness-program/book/confirm')
                } else {
                    message.error('에러가 발생했습니다 개발팀에 문의해주세요')
                }
            }
        })
    }

    return <>
        <Modal transitionName="custom-modal"
            style={{ top: `calc(100vh - ${contentHeight}px)`, padding: 0, margin: 0 }}
            styles={{ content: { minWidth: minWidth, height: `${contentHeight}px`, overflow: "scroll", borderRadius: "5% 5% 0 0", padding: "var(--space20) var(--space20) var(--space40)", marginInline: marginInline + 'px' } }}
            open={isOpen} closeIcon={null} footer={null} onCancel={() => setIsOpen(false)}>
            <div className="title/t1" style={{ color: '#111111', marginBottom: 'var(--space20)' }}>예약 정보</div>
            <div style={{ backgroundColor: 'var(--secondarys100)', padding: 12 }}>
                <Flex gap={16}>
                    <div>
                        <img style={{ width: '48px', height: '48px', objectFit: 'cover', borderRadius: '5px' }} src={bookingData.imageUrl} alt="프로그램 이미지"></img>
                    </div>
                    <div>
                        <div className="sub-title/st3" style={{ minHeight: 27 }}>{bookingData.title}</div>
                        <div className="body/b1 secondary/s500" style={{ marginTop: 5 }}>{bookingData.locationName}</div>
                    </div>
                </Flex>
            </div>
            <div style={{ marginTop: 20 }}>
                <Flex justify="space-between" style={{ borderBottom: '1px solid var(--secondarys100)', padding: '12px 0' }}>
                    <div className="body/b2 secondary/s600">예약 일시</div>
                    <div className="body/b2">{`${formatDate(bookingData.date.toString())}  ${bookingData.startTime}`}{ }</div>
                </Flex>
            </div>

            <div style={{ borderBottom: '1px solid var(--secondarys100)', padding: '12px 0' }}>
                {bookingData.ticketList.filter((ticket: IProgramBookingTicket) => ticket.count > 0).map((ticket: IProgramBookingTicket) => (
                    <Flex justify="space-between" style={{ padding: '4px 0' }}>
                        <div className="body/b2 secondary/s600">{ticket.name}</div>
                        <div>{ticket.count}명</div>
                    </Flex>
                ))}
            </div>

            <div style={{}}>
                {bookingData.optionList.selectOptionList.map((selectOption: ISelectOption) => <>
                    <Flex justify="space-between" style={{ borderBottom: '1px solid var(--secondarys100)', padding: '12px 0' }}>
                        <div className="body/b2 secondary/s600">{selectOption.name}</div>
                        <div className="body/b2">{selectOption.selectedOptionName}</div>
                    </Flex>
                </>)}
            </div>

            <div style={{}}>
                {bookingData.optionList.subjectOptionList.map((subjectOption: ISubjectOption) => <>
                    <Flex justify="space-between" style={{ borderBottom: '1px solid black', padding: '12px 0' }}>
                        <div className="body/b2 secondary/s600">{subjectOption.name}</div>
                        <div className="body/b2">{subjectOption.value}</div>
                    </Flex>
                </>)}
            </div>

            <div style={{}}>
                <Flex justify="space-between" style={{ padding: '12px 0' }}>
                    <div className="sub-title/st2 secondary/s850">합계</div>
                    <div className="sub-title/st2 secondary/s850">{bookingData.ticketList.filter((ticket: IProgramBookingTicket) => ticket.count > 0).reduce((sum, ticket) => sum + (ticket.count * ticket.payAmount), 0).toLocaleString()}원</div>
                </Flex>
            </div>

            <div className="caption/c1 secondary/s500" style={{ textAlign: 'right' }}>※ 예약 확정 시 결제가 완료됩니다.</div>

            <div style={{ paddingTop: '20px' }}>
                <Button
                    color="default"
                    variant="solid"
                    className="sub-title/st3 base/white"
                    style={{ width: '100%', height: '51px', display: 'flex', alignItems: 'center', justifyContent: 'center', }}
                    onClick={() => onClickCreateButton()}>
                    다음
                </Button>
            </div>

            {/* {dataList.map(data => <div style={{ height: '32px', display: 'flex', alignItems: 'center', justifyContent: "space-between", padding: 'var(--space12) 0px var(--space12) var(--space12)', cursor: 'pointer', ...styles?.data }}
                key={data.id}
                onClick={() => { setValue(data.value); setIsOpen(false); }}>
                <div className={data.value === value ? 'sub-title/st3' : 'body/b3'}>
                    {data.label}
                </div>
                {data.value === value && <Check width={16} height={16} stroke={'#000000'} style={{ padding: 'var(--space8)' }} />}
            </div>
            )} */}
        </Modal >
    </>
}

export { ConfirmBottomSheet };


