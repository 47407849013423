import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNoticePopupById } from "../../../../service";
import NotificationDetailSkeleton from "../../../../components/skeleton/notification/notificationDetailSkeleton";
import { TopBar } from "../../../../widgets";
import { DividerThin } from "../../../../shared";
import dayjs from "dayjs";
const NoticePopupDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [noticePopup, setNoticePopup] = useState<INoticePopup | undefined>(undefined);
    const [isInitLoading, setIsIntLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!id) navigate('/notification')
        requestNoticePopupById();
    }, [])

    const requestNoticePopupById = async () => {
        if (!id) return;
        const res = await getNoticePopupById(id)
        setNoticePopup(res.data);
        setIsIntLoading(false);
    }

    return <>
        {isInitLoading && <NotificationDetailSkeleton />}
        <TopBar title="공지 상세" rightIcon={false} />
        <div style={{ padding: '34px 20px 71px' }}>
            {noticePopup && <>
                <div className="body/b1 secondary/s500">{dayjs(noticePopup.createdDate).format("YYYY년 MM월 DD일")}</div>
                <div className="sub-title/st3 base/black" style={{ marginTop: 'var(--space8)' }}>{noticePopup.title}</div>
                <DividerThin style={{ marginBlock: '20px' }} />
                <div className="body/b2 base/black" style={{ whiteSpace: 'pre-wrap' }}>
                    {noticePopup.content}
                </div>
            </>}
        </div>
    </>

}

export { NoticePopupDetail }