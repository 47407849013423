import { Button, Flex, Radio } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom"
import { ReservationBadge } from "../../../reservationBadge";
import { DividerThin } from "../../../etc/dividerThin";
import { RadioChangeEvent } from "antd/lib";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../modules";
import { getSelectedYogaCenteAndDaterAsync } from "../../../../modules/selectedYogaCenterAndDate";
import { ThunkDispatch } from "redux-thunk";

interface IProps {
    dataList: Array<IYogaLectureListByRangeDateAndYogaCenterIdGetResponse> | null
}

const LectureCardInReservation = ({ dataList }: IProps) => {
    const navigate = useNavigate();
    const { yogaCenterId, date } = useSelector((state: RootState) => state.selectedYogaCenterAndDate)
    const update = getSelectedYogaCenteAndDaterAsync;
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const getReservationBadge = (yogaLecture: IYogaLectureListByRangeDateAndYogaCenterIdGetResponse) => {

        if (yogaLecture.isReservation) {
            if (yogaLecture.isCreatedReview) {
                if (dayjs(yogaLecture.endDateTime) < dayjs() && dayjs(yogaLecture.endDateTime) > dayjs().add(-4, 'day')) {
                    return <ReservationBadge status="ReviewUpdate" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        navigate(`/review/update/${yogaLecture.id}`)
                    }} />
                } else {
                    return <ReservationBadge status="ReviewView" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        event.preventDefault();
                        event.stopPropagation();
                        navigate(`/review/view/${yogaLecture.id}`)
                    }} />
                }
            }

            if (yogaLecture.checkInStatus === 'ABSENT') {
                return <ReservationBadge status="Absent" />
            }


            if (dayjs(yogaLecture.endDateTime) < dayjs() && dayjs(yogaLecture.endDateTime) > dayjs().add(-4, 'day')) {
                return <ReservationBadge status="ReviewCreate" onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                    event.preventDefault();
                    event.stopPropagation();
                    navigate(`/review/create/${yogaLecture.id}`)
                }} />
            }

            if (yogaLecture.checkInStatus === 'CHECK_IN') {
                return <ReservationBadge status="CheckIn" />
            }


            return <ReservationBadge status="Complete" />
        }

        if (dayjs().isAfter(dayjs(yogaLecture.startDateTime))) {
            return <ReservationBadge status="Close" />
        }

        if (yogaLecture.maxReservationMember - yogaLecture.nowReservationCnt > 0) {
            return <ReservationBadge status="Available" />
        }

        return <ReservationBadge status="Close" />
    }

    return <>
        {dataList === null
            ? <div style={{ textAlign: "center", fontSize: "14px" }}>조회할 수업이 없습니다.</div>
            : dataList.length === 0
                ? <div className="body/b2 secondary/s600" style={{ textAlign: 'center', marginTop: 24 }}>예약 가능한 수업이 없습니다</div>
                : <div>
                    {dataList.map((yogaLecture, idx) => <div
                        key={idx}
                        style={{ marginTop: 20, padding: '9.5px 0', cursor: 'pointer' }}
                        onClick={() => navigate(`/lecture/${yogaLecture.id}`)}
                    >
                        <Flex style={{ justifyContent: 'space-between' }} gap={16}>
                            <div style={{ width: '64px' }}>
                                <img src={yogaLecture.profileImageUrl} alt="수업사진" width={'100%'} height={'100%'} style={{ borderRadius: "999px" }} />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                                        <div>
                                            <div className="body/b1 secondary/s800">{dayjs(yogaLecture.startDateTime).format('HH:mm')}-{dayjs(yogaLecture.endDateTime).format('HH:mm')}</div>
                                        </div>
                                        {yogaLecture.yogaProgramIsShow &&
                                            <div style={{ color: 'var(--secondarys800)', fontSize: '12px' }}>
                                                | <span style={{ fontWeight: 600, lineHeight: '14.32px' }}>{yogaLecture.yogaProgramName}</span> |
                                            </div>}
                                    </div>
                                    <div>
                                        {getReservationBadge(yogaLecture)}
                                    </div>
                                </div>
                                <div className="sub-title/st3">
                                    {yogaLecture.wellnessLectureName}
                                </div>
                                <div className="body/b1 secondary/s500">
                                    {yogaLecture.teacherName} 코치 · {yogaLecture.yogaCenterName} {yogaLecture.room}
                                </div>
                            </div>
                        </Flex>
                    </div>
                    )}
                </div>
        }
    </>
}

export { LectureCardInReservation }