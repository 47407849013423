import { DateSelectorCarousel, DividerThin, GnbFooter, LectureCardInReservation } from "../../shared"
import CalendarReservation from "../reservation/ui/calendarReservation";
import { WellnessProgram } from "../wellnessProgram";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules";
import WellnessLectureSkeleton from "../../components/skeleton/reservation/wellnessLectureSkeleton";
import { useGetAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse, useGetProgramListWeb } from "./model";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowDown } from "../../assets/images/icon/arrow-down.svg"
import { ReactComponent as ArrowUp } from "../../assets/images/icon/arrow-up.svg"
import { Button, Flex } from "antd";
import { ThunkDispatch } from "redux-thunk";
import { getSelectedYogaCenteAndDaterAsync } from "../../modules/selectedYogaCenterAndDate";
import { useMyContext } from "../../entities/context";
import ReservationSkeleton from "../../components/skeleton/reservation/reservationSkeleton";
import { useLocation } from "react-router-dom";
import { isMobile } from 'react-device-detect';

const Explore = () => {
    const { yogaCenterId, date } = useSelector((state: RootState) => state.selectedYogaCenterAndDate);
    const selectDateRef = useRef<HTMLDivElement | null>(null);
    const startDate = dayjs().add(-30, 'd');
    const endDate = dayjs().add(30, 'd');
    const [isMovedScroll, setIsMovedScroll] = useState<boolean>(false);
    const [isShowCalendar, setIsShowCalendar] = useState<boolean>(false);
    const location = useLocation();
    const paramId = location.search && new URLSearchParams(location.search).get("id");
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const update = getSelectedYogaCenteAndDaterAsync;
    const { topHeight } = useMyContext();
    const [initLoading, setInitLoading] = useState<boolean>(true);

    const { data: wellnessLectures, isLoading: isFetchingLectures, isSuccess } = useGetAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse(yogaCenterId, dayjs(date), dayjs(date))
    const responseQuery = "id title thumbnailImageUrl programPeriod bookingPeriod locationName"
    const { data: programWebList, isLoading } = useGetProgramListWeb(responseQuery)


    useEffect(() => {
        if (paramId) {
            dispatch(update({ yogaCenterId: Number(paramId), date: date }));
        }
    }, [paramId, dispatch, update]);

    useEffect(() => {
        selectDateRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        setIsMovedScroll(true)
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setInitLoading(false);
        }
    }, [isSuccess]);


    const getMonthDaysForToday = () => {
        const days = [];
        for (let date = startDate; date.isBefore(endDate); date = date.add(1, 'day')) {
            const dayObject = {
                day: date.format('ddd'),
                date: date.date(),
                dayjs: date.format("YYYY-MM-DD")
            };
            days.push(dayObject);
        }
        return days;
    };

    const monthDays = getMonthDaysForToday();
    console.log("programWebList", programWebList)
    return <>
        {initLoading && <ReservationSkeleton />}
        <div style={{ paddingTop: topHeight + 'px' }}></div>
        <div className="sub-title/st4" style={{ paddingLeft: 20, color: 'var(--secondarys800)' }}>프로그램</div>
        <div style={{ paddingTop: 24, marginBottom: 40 }}>
            {programWebList && <WellnessProgram dataList={programWebList.map((program: IProgramWebResponse) => {
                return {
                    id: program.id,
                    title: program.title,
                    thumbnailImageUrl: program.thumbnailImageUrl,
                    programPeriod: program.programPeriod,
                    bookingPeriod: program.bookingPeriod,
                    locationName: program.locationName
                }
            })} />}
        </div>

        <div style={{ display: isShowCalendar ? "block" : "none" }}>
            <CalendarReservation reservedDateList={[]} />
        </div>

        <div className="sub-title/st3" style={{ paddingLeft: 20, marginBottom: 16 }}>정규 클래스</div>

        {isMobile ?
            <div style={{ display: isShowCalendar ? "none" : "block" }}>
                <DateSelectorCarousel reservedDateList={[]} selectedDate={date} onClick={(day: string) => dispatch(update({ yogaCenterId: yogaCenterId, date: dayjs(day).format("YYYY-MM-DD") }))} />
            </div>
            :
            <div style={{ display: isShowCalendar ? "none" : "flex", overflow: "auto", whiteSpace: "nowrap" }}>
                {monthDays.map((day, idx) =>
                    <div key={idx}
                        ref={dayjs().format("YYYY-MM-DD") === day.dayjs ? selectDateRef : null}
                        onClick={() => dispatch(update({ yogaCenterId: yogaCenterId, date: day.dayjs }))} style={{ paddingTop: 20, padding: "3%", textAlign: 'center' }}>
                        <div className="sub-title/st1" style={{ display: 'inline-block', width: '25px', color: "var(--secondarys500)" }}>
                            {day.day}
                        </div>
                        <div className="body/b3" style={{
                            marginTop: 4,
                            textAlign: "center",
                            color: "var(--secondarys800)",
                            backgroundColor: date === day.dayjs ? "#FFF743" : day.dayjs === dayjs().format("YYYY-MM-DD") ? "var(--secondarys100)" : undefined,
                            borderRadius: "45%",
                            width: '36px',
                            height: '36px',
                            gap: "6px",
                            cursor: "pointer",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {day.date}
                        </div>
                    </div>)}
            </div>
        }

        {/* <div style={{ textAlign: 'center' }}>
            {isShowCalendar ? <ArrowUp width={16} height={16} style={{ padding: 8, display: 'inline-block' }} stroke={"var(--secondarys400)"} onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }); setIsShowCalendar(false) }} /> :
                <ArrowDown width={16} height={16} style={{ padding: 8, display: 'inline-block' }} stroke={"var(--secondarys400)"} onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }); setIsShowCalendar(true) }} />}
        </div> */}

        {isMovedScroll && <>
            <div style={{ marginTop: '8px', paddingInline: '20px' }}>
                <Flex style={{ marginTop: 20 }}>
                    <Button onClick={() => dispatch(update({ yogaCenterId: 0, date: date }))} color="default" variant={yogaCenterId === 0 ? "solid" : "outlined"} className="body/b2" style={{ padding: '8px 12px', marginRight: '12px', borderRadius: '64px' }}>전체</Button>
                    <Button onClick={() => dispatch(update({ yogaCenterId: 3, date: date }))} color="default" variant={yogaCenterId === 3 ? "solid" : "outlined"} className="body/b2" style={{ padding: '8px 12px', marginRight: '12px', borderRadius: '64px' }}>선릉</Button>
                    <Button onClick={() => dispatch(update({ yogaCenterId: 2, date: date }))} color="default" variant={yogaCenterId === 2 ? "solid" : "outlined"} className="body/b2" style={{ padding: '8px 12px', marginRight: '12px', borderRadius: '64px' }}>도산</Button>
                    <Button onClick={() => dispatch(update({ yogaCenterId: 1, date: date }))} color="default" variant={yogaCenterId === 1 ? "solid" : "outlined"} className="body/b2" style={{ padding: '8px 12px', marginRight: '12px', borderRadius: '64px' }}>강남</Button>
                </Flex>
                {!isFetchingLectures && wellnessLectures ? (<>
                    <LectureCardInReservation dataList={wellnessLectures} />
                </>
                ) : (
                    <WellnessLectureSkeleton />
                )}
            </div>

        </>}


        <GnbFooter activeButton='explore' />
    </>
}

export { Explore }