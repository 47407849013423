import { useMutation, useQuery } from "@tanstack/react-query";
import { createNickNameWeb, getNickNameWeb } from "../../../service/nickName";
import { deleteFeedCommentWeb } from "../../../service/feed";

const useGetNickName = () => {
    const query = useQuery({
        queryKey: ['nickName'],
        queryFn: () => getNickNameWeb(),
        select: (res) => res.data,
    });

    return query
}

const useCreateNickNameWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: createNickNameWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useDeleteFeedCommentWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: deleteFeedCommentWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;

}
export { useGetNickName, useCreateNickNameWeb, useDeleteFeedCommentWeb }