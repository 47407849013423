import { Button, Divider, Flex, message } from "antd";
import { useCreateFeedLikeWeb, useDeleteFeedLikeWeb, useGetFeedListWeb } from "./model";
import dayjs from "dayjs";
import { ReactComponent as More } from "../../../../assets/images/icon/more.svg"
import { ReactComponent as FillOff } from "../../../../assets/images/icon/fill=off.svg"
import { ReactComponent as FillOn } from "../../../../assets/images/icon/fill=on.svg"
import { ReactComponent as Bubble } from "../../../../assets/images/icon/bubble.svg"
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FeedClaimBottomSheet } from "../feedClaimBottomSheet";
import { useNavigate } from "react-router-dom";

const RecommendFeed = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: feedList } = useGetFeedListWeb();
    const [isOpenFeedClaimModal, setIsOpenFeedClaimModal] = useState<boolean>(false);
    const [feedId, setFeedId] = useState<number | undefined>(undefined);

    const createFeedLikeWeb = useCreateFeedLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedList'] })
        }
    })

    const deleteFeedLikeWeb = useDeleteFeedLikeWeb((res: any) => {
        if (res.data) {
            message.success("피드 좋아요 성공했습니다.")
            queryClient.invalidateQueries({ queryKey: ['feedList'] })
        }
    })

    return <>
        {feedList?.map((feed: IGetFeedListWebResponse) => (<>
            <Divider style={{ margin: 0 }} />
            <div style={{ padding: 20 }}>
                <Flex justify="space-between">
                    <Flex style={{ alignItems: 'center' }} gap={8}>
                        <div className="sub-title/st2">{feed.nickName}</div>
                        <div className="caption/c1 secondary/s500">{dayjs(feed.createdDate).format('YY.MM.DD')}</div>
                    </Flex>
                    <div>
                        <Button type="text" icon={<More />} onClick={() => {
                            setFeedId(feed.id)
                            setIsOpenFeedClaimModal(true)
                        }}></Button>
                    </div>
                </Flex>

                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/community/feed/${feed.id}`)}
                >
                    <div className="body/b3" style={{ marginTop: 8 }}>{feed.content}</div>
                    <div style={{ marginTop: 8 }}>
                        <Flex style={{ overflow: 'scroll' }} gap={12}>
                            {feed.imageUrlList.map((imageUrl: string) => {
                                return <div style={{}}><img src={imageUrl} style={{ maxHeight: 200, borderRadius: 4 }} /></div>
                            })}
                        </Flex>
                    </div>
                </div>
                <div style={{ marginTop: 8 }}>
                    <Flex gap={30}>
                        {feed.isCreatedLike
                            ? <Button type="text" icon={<FillOn />} style={{ color: 'var(--errore400)' }} onClick={() => deleteFeedLikeWeb.mutate(feed.id)}>{feed.feedLikeCnt}</Button>
                            : <Button type="text" icon={<FillOff />} onClick={() => createFeedLikeWeb.mutate(feed.id)}>{feed.feedLikeCnt}</Button>}
                        <Button type="text" icon={<Bubble />}>{feed.feedCommentCnt}</Button>
                    </Flex>
                </div>
            </div>
        </>))}

        {feedId && <FeedClaimBottomSheet
            feedId={feedId}
            isOpen={isOpenFeedClaimModal}
            setIsOpen={setIsOpenFeedClaimModal}
        />}

    </>
}

export default RecommendFeed