import { Button, Card, Flex } from "antd"
import dayjs from "dayjs"
import { ReactComponent as Clock } from "../../../../../assets/images/icon/clock.svg"
import { useNavigate } from "react-router-dom"

interface IProps {
    reservationList: Array<IGetMyReservationHistoryResponse>
}

const PendingReservation = ({ reservationList }: IProps) => {
    const navigate = useNavigate();
    return <>
        {reservationList.length === 0 ? <>
            <div style={{ marginTop: 250, textAlign: 'center' }}>
                <div className="body/b2 secondary/s800">대기중인 프로그램이 없습니다.</div>
                <div style={{ marginTop: 8 }}>
                    <Button color="default" variant="outlined" onClick={() => navigate('/explore')}> 프로그램 둘러보기</Button>
                </div>
            </div>
        </> : reservationList.map((reservation: IGetMyReservationHistoryResponse) => <>
            <Card
                className="custom-card"
                hoverable
                onClick={() => navigate(`/program/book/detail/${reservation.reservationId}`)}
                style={{ width: '100%', maxHeight: 300, marginBottom: 32 }}
                cover={<img alt="example" src={reservation.imageUrl} style={{ maxHeight: 180, objectFit: 'cover' }} />}
            >
                <div className="sub-title/st4">{reservation.title}</div>
                <Flex justify="space-between" style={{ marginTop: 20 }}>
                    <div className="secondary/s500">
                        <div>{dayjs(reservation.startDateTime).format('YYYY.MM.DD (dd) HH:mm')}</div>
                        <div>{reservation.yogaCenterName}</div>
                    </div>
                    <Flex style={{ alignItems: 'center' }} gap={3}>
                        <Clock />
                        <div className="sub-title/st1 primary/p800">대기중</div>
                    </Flex>
                </Flex>
            </Card>
        </>)}
    </>
}

export default PendingReservation