import { Flex, Skeleton } from "antd";
import { useMyContext } from "../../../../entities/context";

const WellnessLectureSkeleton = () => {
    const { topHeight } = useMyContext();
    const WellenssLectureCardSkeleton = () => {
        return <div style={{ marginTop: '20px', paddingBlock: '8px' }}>
            <Flex gap={16}>
                <Skeleton.Avatar active={true} size={"default"} shape={'circle'} style={{ width: 72, height: 72 }} />
                <div>
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 80 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, width: 160, marginTop: 4 }} />
                    <Skeleton.Button active={true} size={"default"} block={true} style={{ height: 20, marginTop: 12 }} />
                </div>
            </Flex>
        </div>
    }
    const WellenssLectureCardSkeletonLenght = Array(6).fill(null);

    return (<div>

        <div style={{ marginTop: '24px', paddingInline: '20px' }}>
            {WellenssLectureCardSkeletonLenght.map((_, index) => <WellenssLectureCardSkeleton key={index} />)}
        </div>
    </div>)
}

export default WellnessLectureSkeleton
