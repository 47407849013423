import { EditOutlined } from "@ant-design/icons"

interface IProps {
    status: 'Complete' | 'Available' | 'Close' | 'ReviewCreate' | 'ReviewView' | 'Absent' | 'CheckIn' | 'ReviewUpdate'
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ReservationBadge = ({ status, onClick }: IProps) => {

    const style = () => {
        if (status === 'Complete') {
            return { color: 'var(--secondarys500)' }
            // } else if (status === 'Available') {
            //     return { border: '0.75px solid var(--secondarys500)', color: 'var(--secondarys700)' }
        } else if (status === 'Close') {
            return { color: 'var(--errore700)' }
        } else if (status === 'ReviewCreate') {
            return {}
        } else if (status === 'ReviewView') {
            return {}
        } else if (status === 'Absent') {
            return { color: '#ff3333' }
        } else if (status === 'CheckIn') {
            return {}
        } else if (status === 'ReviewUpdate') {
            return { color: 'white' }
        }
    }

    return <div
        className="caption/c1"
        style={{ ...style(), padding: '4px 8px', borderRadius: '4px', lineHeight: '14px', minWidth: 22 }}
    >
        {status === 'Complete' ? '예약완료'
            : status === 'CheckIn' ? '출석완료'
                // : status === 'Available' ? '가능'
                : status === 'Close' ? '마감'
                    : status === 'Absent' ? '결석'
                        : status === 'ReviewCreate' ? <div onClick={onClick}>리뷰 작성 <EditOutlined /></div>
                            : status === 'ReviewUpdate' ? <div onClick={onClick}>리뷰 수정 <EditOutlined /></div>
                                : status === 'ReviewView' ? <div onClick={onClick}>리뷰 확인</div>
                                    : ''}
    </div>
}
export { ReservationBadge }