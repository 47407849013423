import { useEffect } from "react";
import { Carousel } from "../../shared"
import { getBannerListForMobile } from "../../service";
import { useQuery } from "@tanstack/react-query";

interface IProps {
    setImageLoaded?: Function;
}

const defaultBannerList: IBanner[] = [{
    id: 0,
    name: 'basic',
    startDateTime: '1999-01-01',
    endDateTime: '3000-12-31',
    imageUrl: 'https://img.positivehotel.io/2024/7/17/240717-yoga-banner.jpg',
    showOrdering: '1',
    linkUrl: '/',
    isShow: true,
    createdDate: '2024-09-02',
    lastUpdatedDate: '2024-09-02',
}]

const BannerCarousel = ({ setImageLoaded }: IProps) => {
    const { data = [], isPending, isError, error } = useQuery({
        queryKey: ['bannerList'],
        queryFn: getBannerListForMobile,
        select: (res) => {
            const bannerList = res.data.length > 0 ? res.data : defaultBannerList;
            const img = new Image();
            img.src = bannerList[0].imageUrl;
            img.onload = () => setImageLoaded?.(true);
            return bannerList;
        }
    });

    useEffect(() => {
        if (isError) {
            console.error('Error: ', error);
        }
    }, [isError, error]);


    useEffect(() => {
    }, [isError, error])
    return <>
        <Carousel carouselList={data.sort((a, b) => Number(b.showOrdering) - Number(a.showOrdering))} />
    </>
}

export { BannerCarousel }