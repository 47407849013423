import { useMutation } from "@tanstack/react-query";
import { createFeedWeb } from "../../../../service/feed";

const useCreateFeedWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: ({ content, imageUrlList }: { content: string, imageUrlList: Array<string> }) => createFeedWeb(content, imageUrlList),
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

export { useCreateFeedWeb }