import { useQuery } from "@tanstack/react-query";
import { WellnessProgramCalrousel } from "./calrousel";

interface IProps {
    dataList: Array<IData>
}
interface IData {
    id: number,
    title: string,
    thumbnailImageUrl: string,
    programPeriod: Array<string>,
    bookingPeriod: Array<string>,
    locationName: string
}

const WellnessProgram = ({ dataList }: IProps) => {
    return <>
        <div className="sub-title/st3" style={{ padding: '20px' }}>브랜드 프로그램</div>
        <WellnessProgramCalrousel carouselList={dataList} />
    </>
}

export { WellnessProgram }