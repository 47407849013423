import { useQuery } from "@tanstack/react-query";
import { getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse } from "../../../service";
import { Dayjs } from "dayjs";
import { getProgramListWeb } from "../../../service/program";

const useGetAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse = (yogaCenterId: number, startDate: Dayjs, endDate: Dayjs) => {
    const query = useQuery({
        queryKey: ['wellnessLectures', yogaCenterId, startDate, endDate],
        queryFn: () => getAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse(
            yogaCenterId,
            startDate.startOf('d').toISOString(),
            endDate.endOf('d').toISOString()
        ),
        select: (res) => res.data,
        enabled: !!startDate && !!endDate,
    });

    return query
}


const useGetProgramListWeb = (responseQuery?: string) => {
    const query = useQuery({
        queryKey: ['getProgramListWeb', responseQuery],
        queryFn: () => getProgramListWeb(responseQuery),
        select: res => {
            console.log("res", res.data.data.getProgramListWeb)
            return res.data.data.getProgramListWeb
        }
    });
    return query;
}


export { useGetAllWellnessLectureByYogaCenterIdAndMemberIdAndStartDateTimeBetweenAndIsDeleteFalse, useGetProgramListWeb }