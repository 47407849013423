import { useQuery } from "@tanstack/react-query"
import { getProgramById } from "../../../../../service/program";

const useGetProgramById = (id: number, responseQuery?: string) => {
    const query = useQuery({
        queryKey: ['getProgramById', id, responseQuery],
        queryFn: () => getProgramById(id, responseQuery),
        enabled: !!id,
        select: res => res.data.data.getProgramWebById,
    });
    return query;
}

export { useGetProgramById };