import { useMutation, useQuery } from "@tanstack/react-query";
import { createFeedClaimWeb, createFeedCommentLikeWeb, createFeedCommentWeb, createFeedLikeWeb, deleteFeedCommentLikeWeb, deleteFeedLikeWeb, getFeedListWeb } from "../../../../../service/feed";

const useGetFeedListWeb = () => {
    const query = useQuery({
        queryKey: ['feedList'],
        queryFn: () => getFeedListWeb(),
        select: (res) => res.data,
    });
    return query
}

const useCreateFeedLikeWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: createFeedLikeWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useDeleteFeedLikeWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: deleteFeedLikeWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useCreateFeedClaimWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: createFeedClaimWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useCreateFeedCommentWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: createFeedCommentWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useCreateFeedCommentLikeWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: createFeedCommentLikeWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

const useDeleteFeedCommentLikeWeb = (callback: Function) => {
    const mutation = useMutation({
        mutationFn: deleteFeedCommentLikeWeb,
        onSuccess: (res) => { res.data && callback(res) }
    });
    return mutation;
}

export { useGetFeedListWeb, useCreateFeedLikeWeb, useDeleteFeedLikeWeb, useCreateFeedClaimWeb, useCreateFeedCommentWeb, useCreateFeedCommentLikeWeb, useDeleteFeedCommentLikeWeb }