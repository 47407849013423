import { Navigate, useParams } from "react-router-dom";
import { TopBar } from "../../../../widgets";
import dayjs from "dayjs";
import { BottomSheet } from "../../../../shared";
import { useState } from "react";
import { Flex, Input } from "antd";
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from "react-redux";
import { getProgramBookingAsync } from "../../../../modules/programBooking";
import { RootState } from "../../../../modules";
import BookingSecondStepFooter from "./footer";
import { ReactComponent as ArrowDown } from "../../../../assets/images/icon/arrow-down.svg"
import { ConfirmBottomSheet } from "./confirmBottomSheet";
import { useGetProgramById } from "../firstStep/model";

const WellnessProgramBookSecondStep = () => {
    const id = Number(useParams().id);
    const dispatch: ThunkDispatch<any, any, any> = useDispatch();
    const update = getProgramBookingAsync;
    const { form, ticketList } = useSelector((state: RootState) => state.programBooking);
    const [isShowBottomSheet, setIsShowBottomSheet] = useState<boolean>(false);
    const [isShowConfirmButtomSheet, setIsShowConfirmButtomSheet] = useState<boolean>(false);
    const [bottomSheetDataList, setBottomSheetDataList] = useState<Array<IBottomSheet>>([]);
    const [selectedOptionType, setSelectedOptionType] = useState<string>('')
    const { data, isPending, isError } = useGetProgramById(id, 'programSelectOptionList { id idx name description isRequired } programSelectOptionItemList { id name programSelectOptionId } programSubjectOptionList { id idx name description isRequired }')


    const updateForm = (key: string, value: number | string) => {
        const updatedCount = { ...form, [key]: value };
        dispatch(update({
            form: updatedCount,
            ticketList: ticketList
        }))
    }

    const getTotalBookingCnt = () => {
        return ticketList.reduce((sum, ticket) => sum + ticket.count, 0);
    }

    const formatDate = (dateString: string): string => {
        return dayjs(dateString).format("YYYY.MM.DD (ddd)");
    };

    const getBookingData = (): IBookingData => {
        const returnValue = {
            programId: id,
            title: form[`title`].toString(),
            imageUrl: form[`imageUrl`].toString(),
            locationName: form[`locationName`].toString(),
            date: form[`date`].toString(),
            startTime: form[`startTime`].toString(),
            totalBookingCtn: getTotalBookingCnt(),
            totalPrice: Number(form['totalPrice']),
            eventId: Number(form['eventId']),
            optionList: {
                selectOptionList: data?.programSelectOptionList ? data.programSelectOptionList.map((selecteOption: any) => ({
                    id: selecteOption.id,
                    idx: selecteOption.idx,
                    name: selecteOption.name,
                    description: selecteOption.description,
                    isRequired: selecteOption.isRequired,
                    selectedOptionId: form[`programSelectOption-${selecteOption.id}`].toString(),
                    selectedOptionName: data.programSelectOptionItemList.find((item: any) => item.id === form[`programSelectOption-${selecteOption.id}`])?.name
                })) : [],
                subjectOptionList: data?.programSubjectOptionList ? data.programSubjectOptionList.map((subjectOption: any) => ({
                    id: subjectOption.id,
                    idx: subjectOption.idx,
                    name: subjectOption.name,
                    description: subjectOption.description,
                    isRequired: subjectOption.isRequired,
                    value: form[`programSubjectOption-${subjectOption.id}`].toString()
                })) : []
            },
            ticketList: ticketList
        }
        return returnValue
    }

    if (isNaN(id)) return <Navigate to={'/explore'} />
    if (!form.date) return <Navigate to={`/wellness-program/book/first-step/${id}`} />

    return <>
        <TopBar title="예약" />
        <div>
            {data?.programSelectOptionList?.map((selecteOption: any) =>
                <div key={selecteOption.id} style={{ padding: '20px' }}>
                    <div className="sub-title/st3">
                        {selecteOption.name}
                    </div>
                    <div className="body/b2 secondary/s500">
                        {selecteOption.description}
                    </div>
                    <div
                        onClick={() => {
                            setSelectedOptionType(`programSelectOption-${selecteOption.id}`)
                            setBottomSheetDataList(data.programSelectOptionItemList.filter((item: IProgramSelectOptionItem) => item.programSelectOptionId.toString() === selecteOption.id).map((item: any) => ({ id: item.id, label: item.name, value: item.id })))
                            setIsShowBottomSheet(true)
                        }}
                        style={{ border: '1px solid var(--secondarys300)', borderRadius: 'var(--radius4)', marginTop: 20, cursor: 'pointer' }}
                    >
                        <Flex style={{ minHeight: 17, padding: '12px 16px', alignItems: 'center' }}>
                            <div className="sub-title/st2 secondary/s600" style={{ flex: 1 }}>{data.programSelectOptionItemList.find((item: any) => item.id === form[`programSelectOption-${selecteOption.id}`])?.name}</div>
                            <div><ArrowDown width={16} height={16} stroke={"#AEAEAE"} /></div>
                        </Flex>
                    </div>
                </div>)}
        </div>
        <div>
            {data?.programSubjectOptionList?.map((subjectOption: any) =>
                <div key={subjectOption.id} style={{ padding: '20px' }}>
                    <div className="sub-title/st3">
                        {subjectOption.name}
                    </div>
                    <div className="body/b2 secondary/s500" >
                        {subjectOption.description}
                    </div>
                    <Input
                        style={{ borderRadius: 'var(--radius4)', marginTop: 20 }}
                        size="large"
                        onChange={(q) => updateForm(`programSubjectOption-${subjectOption.id}`, q.target.value)}
                        placeholder={'내용을 적어주세요.'} />
                </div>)}
        </div>

        <BookingSecondStepFooter
            date={`${formatDate(form.date.toString())}  ${form.startTime}`}
            totalBookingCnt={getTotalBookingCnt()}
            onClickBookingBtn={() => setIsShowConfirmButtomSheet(true)} />

        <BottomSheet
            isOpen={isShowBottomSheet}
            setIsOpen={setIsShowBottomSheet}
            dataList={bottomSheetDataList}
            value={form[selectedOptionType]}
            setValue={(value: string) => {
                setIsShowBottomSheet(false)
                updateForm(selectedOptionType, value)
            }}
            isShowLabel={false}
        />

        {isShowConfirmButtomSheet &&
            <ConfirmBottomSheet
                isOpen={isShowConfirmButtomSheet}
                setIsOpen={setIsShowConfirmButtomSheet}
                bookingData={getBookingData()}
            />}
    </>
}

export { WellnessProgramBookSecondStep }